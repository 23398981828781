import React from 'react'
import { withStyles, Divider } from '@material-ui/core'

const styles = theme => ({
  main: {
    height: 1,
    width: '100%',
    background:
      'linear-gradient(to right, white 20%, #E1E1E8,  #CBCBD1, #E1E1E8,  white 80%)'
  }
})

class HDivider extends React.Component {
  render() {
    const { classes, margin, isDefault, ...props } = this.props
    return isDefault ? (
      <Divider {...props} />
    ) : (
      <Divider className={classes.main} style={{ margin: margin }} />
    )
  }
}

export default withStyles(styles)(HDivider)
