import React from 'react'
import { withStyles, FormLabel, FormControl, FormGroup } from '@material-ui/core'

const styles = theme => ({
  root: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  label: {
    fontSize: 16,
    fontWeight: 700
  }
})

const CheckboxGroup = ({ classes, label, value, children, ...props }) => (
  <FormControl className={classes.root}>
    <FormLabel className={classes.label}>{label}</FormLabel>
    <FormGroup {...props}>{children}</FormGroup>
  </FormControl>
)

export default withStyles(styles)(CheckboxGroup)
