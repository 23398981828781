var currentAdapter;
/**
 * Sets or gets the api client instance
 */

export default function apiClient(adapter) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (adapter) {
    currentAdapter = Object.assign({}, adapter, options);
  }

  if (!currentAdapter) {
    throw new Error('You must set an adapter first!');
  }

  return currentAdapter;
}