import React from 'react'
import { Dialog } from 'draft'
import { withStyles, CircularProgress } from '@material-ui/core'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  label: {
    paddingTop: 10,
    paddingRight: 30,
    paddingLeft: 30,
    color: theme.palette.text.secondary
  }
})

export default
@withStyles(styles)
class ModalBusy extends React.Component {
  render() {
    const { classes, busy, label = 'Aguarde...', noLabel = false } = this.props
    return (
      <Dialog
        open={!!busy}
        fullScreen={false}
        content={
          <div className={classes.container}>
            <CircularProgress />
            {!noLabel && <div className={classes.label}>{label}</div>}
          </div>
        }
      />
    )
  }
}
