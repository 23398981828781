import React from 'react'

class Label extends React.Component {
  state = {
    style: {
      fontWeight: this.props.fontWeight,
      fontSize: this.props.fontSize,
      color: this.props.color ? this.props.color : 'black',
      margin: this.props.margin ? this.props.margin : 0
    }
  }

  render(...props) {
    return (
      <label {...props} style={this.state.style}>
        {this.props.children}
      </label>
    )
  }
}

export default Label
