import React from 'react'
import Highcharts from 'highcharts'
import noData from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { Paper, withStyles } from '@material-ui/core'
import PeriodNavigator from '../../molecules/PeriodNavigator'
import moment from 'moment'

require('highcharts/modules/exporting')(Highcharts)
noData(Highcharts)

const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

const styles = theme => ({
  paper: {
    padding: 10,
    flex: 1,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column'
  },
  toolbar: {
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    minHeight: 50,
    marginRight: 50
  },
  chartContainer: {
    marginTop: -50
  }
})

export default
@withStyles(styles)
class ColumnChart extends React.Component {
  formatter = props =>
    function() {
      if (props.parseDate) {
        let date = moment.utc(this.value)
        return date.format('DD/MM') + ' <b>' + weekDays[date.day()] + '</b>'
      } else if (props.currentMonth) {
        let date = moment()
          .year(props.currentYear)
          .month(props.currentMonth)
          .date(this.value)
        return this.value + ' <b>' + weekDays[date.day()] + '</b>'
      } else {
        return this.value
      }
    }

  tooltip = props =>
    function() {
      if (props.tooltip) {
        return props.tooltip
      } else {
        var label = this.x

        if (props.parseDate) {
          let date = moment.utc(this.x)
          label = date.format('DD/MM') + ' <b>' + weekDays[date.day()] + '</b>'
        } else if (props.currentMonth) {
          let date = moment()
            .year(props.currentYear)
            .month(props.currentMonth)
            .date(this.x)
          label = this.x + ' <b>' + weekDays[date.day()] + '</b>'
        } else if (this.points.length > 0) {
          label = this.points[0].key
        }

        var s = ' <b> ' + label + '</b>'

        var total = 0

        this.points.forEach(item => {
          s +=
            '<br/><span style="font-weight: 500"><span style="color:' +
            item.series.color +
            '"> ● </span>' +
            item.series.name +
            '</span>: ' +
            (props.round ? item.y.toFixed() : item.y.toLocaleString())

          total += item.y

          if (!props.disablePercent) {
            s += ' (' + (item.percentage || 0).toLocaleString() + '%)'
          }
        })

        s += ' <br/><span style="font-size:13;font-weight: 700;margin: 5px;"> Total: ' + (props.round ? total.toFixed() : total.toLocaleString()) + '</span>'

        return s
      }
    }

  plotOptions = props => {
    let pOtions = {
      column: {
        stacking: this.props.percent ? 'percent' : 'normal',
        borderWidth: 0
      }
    }

    if (this.props.onClick) {
      pOtions.series = {
        cursor: 'pointer',
        point: {
          events: {
            click: function() {
              props.onClick(this)
            }
          }
        }
      }
    }
    return pOtions
  }

  getChartData = (props = this.props) => ({
    credits: {
      enabled: false
    },
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Roboto'
      }
    },
    legend: {
      itemStyle: {
        fontWeight: 300
      }
    },
    title: {
      text: this.props.title,
      align: 'right',
      x: -30,
      y: 18
    },
    subtitle: {
      text: this.props.description,
      align: 'right'
    },
    xAxis: {
      type: 'category',
      categories: this.props.categories,
      labels: {
        formatter: this.formatter(props)
      }
    },
    yAxis: {
      crosshair: true,
      min: 0,
      title: {
        text: ''
      }
    },
    lang: {
      noData: 'Sem dados para o período selecionado',
      contextButtonTitle: 'Download Gráfico',
      printChart: 'Imprimir',
      downloadCSV: 'Download CSV',
      downloadJPEG: 'Download JPEG',
      downloadPDF: 'Download PDF',
      downloadPNG: 'Download PNG',
      downloadSVG: 'Download SVG',
      downloadXLS: 'Download XLS'
    },
    loading: {
      style: {
        opacity: 0.8
      },
      labelStyle: {
        fontWeight: 400
      }
    },
    noData: {
      style: {
        fontWeight: 300,
        fontSize: 18
      }
    },
    tooltip: {
      formatter: this.tooltip(props),
      shared: true
    },
    plotOptions: this.plotOptions(props),
    series: this.props.series
  })

  render() {
    if (this.chart) {
      if (this.props.loading) {
        this.chart.chart.showLoading('Carregando...')
      } else {
        this.chart.chart.hideLoading()
      }
    }

    // console.log(this.props.showPeriodIndication)

    const { classes, currentSelection, currentLabel, onBack, onNext, toolBar } = this.props
    return (
      <Paper className={classes.paper}>
        <div className={classes.toolbar}>
          {currentSelection && <PeriodNavigator currentSelection={currentSelection} currentLabel={currentLabel} onBack={onBack} onNext={onNext} />}
          {toolBar}
        </div>
        <div className={classes.chartContainer}>
          <HighchartsReact ref={me => (this.chart = me)} highcharts={Highcharts} options={this.getChartData()} />
        </div>
      </Paper>
    )
  }
}
