import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: '10px 15px 10px 15px',
    backgroundColor: '',
    maxWidth: 100,
    maxHeight: 100
  }
})

const ProfilePicture = ({ src, classes }) => {
  return <img className={classes.root} src={src} alt={'Foto de perfil'} />
}

ProfilePicture.propTypes = {
  classes: PropTypes.object,
  src: PropTypes.string.isRequired
}

export default withStyles(styles)(ProfilePicture)
