import BaseUserStore from "./UserStore";
import BaseAccessRoutesStore from "./AccessRoutesStore";
import BaseRealmStore from "./RealmStore";
import BaseAccountStore from "./AccountStore";
import BaseUserDetailStore from "./UserDetailStore";
import BaseContactDataStore from "./ContactDataStore";
export { default as AppStore } from "./AppStore";
export { default as RouterStore } from "./RouterStore";
export var UserStore = new BaseUserStore();
export var AccessRoutesStore = new BaseAccessRoutesStore({
  where: {
    enabled: true,
    accessRouteId: null
  },
  order: ['orderIndex'],
  include: 'accessRoutes'
});
export var AccountStore = new BaseAccountStore();
export var UserDetailStore = new BaseUserDetailStore();
export var RealmStore = new BaseRealmStore();
export var ContactDataStore = new BaseContactDataStore({
  include: {
    city: 'state'
  }
});