import React from 'react'
import { withStyles } from '@material-ui/core/styles'
//import Label from '../../atoms/Label' --------- CONSERTAR O ATOM DEPOIS
import Input from '../../atoms/Input'
// import Paper from '../../atoms/Paper'

const styles = theme => ({
  root: {
    padding: 15,
    //flex: 1,
    // display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderTop: '1px solid #F1F1F1'
  },

  label: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10
  },

  input: {
    marginTop: 10
  },

  disabledLabel: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 10,
    color: '#C7C7CD'
  },

  disabledInput: {
    color: '#C7C7CD',
    padding: '10px 12px',
    fontSize: 14,
    fontWeight: 400
  }
})

const Field = ({
  classes,
  enabled = true,
  label,
  className,
  disabledLabel,
  ...props
}) => {
  return enabled ? (
    <div className={`${classes.root} ${className}`}>
      <label className={classes.label}>{label}</label>
      <Input className={classes.input} {...props} />
    </div>
  ) : (
    <div className={`${classes.root} ${className}`}>
      <label className={classes.disabledLabel}>{label}</label>
      <label className={classes.disabledInput}>{disabledLabel}</label>
    </div>
  )
}

export default withStyles(styles)(Field)
