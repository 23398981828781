import React from 'react'
import CustomInput from '../../molecules/CustomInput'
import { Grid, FormLabel, withStyles } from '@material-ui/core'

const styles = theme => ({
  grid: {
    padding: '0 15px'
  },
  labelHorizontal: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '39px',
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right'
    }
  }
})

export default
@withStyles(styles)
class FormContainer extends React.Component {
  render() {
    const { classes, label, value, disabled, input } = this.props
    return (
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.grid}>
          <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          {input || (
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: value,
                disabled: disabled
              }}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}
