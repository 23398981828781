import React from 'react'
import {
  withStyles,
  FormHelperText,
  FormControl,
  InputLabel,
  LinearProgress
} from '@material-ui/core'
import Uploader from './../Uploader'

const styles = theme => ({
  container: {
    marginTop: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    lineHeight: '1.1875em',
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
  },
  input: {
    font: 'inherit',
    color: 'currentColor',
    border: 0,
    margin: 0,
    padding: '6px 0 7px',
    display: 'block',
    minWidth: 0,
    flexGrow: 1,
    boxSizing: 'content-box',
    background: 'none',
    verticalAlign: 'middle',
    tapHighlightColor: 'transparent'
  }
})

export default
@withStyles(styles)
class File extends React.Component {
  state = {
    uploadComplete: false,
    uploading: false,
    progress: 0
  }

  handleError = e => {
    this.setState({ uploading: false, uploadError: true })
  }

  handleProgress = value => {
    this.setState({ progress: value, uploading: true })
  }

  handleFinish = result => {
    this.setState({
      uploadComplete: true,
      uploading: false,
      currentPicture: result.filename
    })
    this.props.onUploadFinish && this.props.onUploadFinish(result)
  }

  render() {
    const {
      classes,
      autoUpload = true,
      label,
      helper,
      required,
      ...props
    } = this.props
    const { uploading, progress } = this.state

    return (
      <FormControl required={required} fullWidth>
        <InputLabel shrink>{label}</InputLabel>
        <div className={classes.container}>
          <Uploader
            {...props}
            required={required}
            className={classes.input}
            disabled={uploading}
            accept="image/*"
            onProgress={this.handleProgress}
            onError={this.handleError}
            onFinish={this.handleFinish}
            autoUpload={autoUpload}
          />
        </div>
        {uploading && (
          <LinearProgress
            color="primary"
            variant="determinate"
            value={progress}
          />
        )}
        {helper && <FormHelperText>{helper}</FormHelperText>}
      </FormControl>
    )
  }
}
