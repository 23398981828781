import React from 'react'
import { withStyles } from '@material-ui/core'
import { inject } from '@faicon/core'
import { Paper, Divider, IconButton, MenuItem, Chip, CircularProgress, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Favorite from '@material-ui/icons/Favorite'
import Done from '@material-ui/icons/Done'
import History from '@material-ui/icons/History'
import Add from '@material-ui/icons/Add'
import SyncProblem from '@material-ui/icons/SyncProblem'
import { I18n } from 'react-i18nify'
import cs from 'classnames'

const styles = {
  root: {
    width: '100%',
    padding: 10,
    paddingBottom: -5,
    justifyContent: 'center'
  },
  chip: {
    margin: 2,
    paddingRight: 4,
    paddingLeft: 4,
    paddingTop: 1,
    fontSize: 11,
    lineHeight: 1.1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  vertical: { display: 'flex', flexDirection: 'column' },
  horizontal: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon2: {
    height: 22,
    width: 22
  },
  icon: {
    marginTop: -2,
    marginRight: -6,
    padding: 0,
    height: 36,
    width: 36,
    flexShrink: 0
  },
  square: {
    height: 32,
    width: 32,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    background: '#11c2db'
  },
  circle1: {
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#78c541'
  },
  circle2: {
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f2bd0e'
  },
  circle3: {
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#dc4a4c'
  },
  circle4: {
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#328eb6'
  }
}

export default
@withStyles(styles)
@inject('router')
class ProductRenderer extends React.Component {
  state = {
    daysDiffDate: 0,
    synchronizing: false
  }

  componentWillUnmount() {
    this._mounted = false
  }

  componentDidMount() {
    this._mounted = true
    // let endDate = DateUtil.addMonths(this.props.product.bought_at, this.props.product.warrant_in_days + this.props.product.extended_warrant_in_days)
    // let daysDiffDate = DateUtil.getDifferenceInDaysWithoutHours(Date.now(), endDate)
    // this.setState({ daysDiffDate: daysDiffDate })
    this.checkNeedSync()
  }

  labelDateText = () => {
    // let endDate = DateUtil.addMonths(this.props.product.bought_at, this.props.product.warrant_in_days + this.props.product.extended_warrant_in_days)
    let daysDiffDate = this.state.daysDiffDate

    if (daysDiffDate === 1) {
      return I18n.t('ultima_dia')
    } else if (daysDiffDate > -1) {
      // return I18n.t('vencido_a') + ' ' + DateUtil.getMoment(endDate).fromNow() + '.'
    } else {
      // return I18n.t('garantido_ate') + ' ' + DateUtil.getMoment(endDate).fromNow() + '.'
    }
  }

  openProduct = event => {
    event.preventDefault()
    // hashHistory.push(`p/${this.props.product.id}`)
    event.stopPropagation()
  }

  handleMore = event => {
    event.stopPropagation()
  }

  checkNeedSync = () => {
    if (this.props.product.needsSave) {
      // Globals.addUploadQueue(this)
    }
  }

  render() {
    const { product, classes } = this.props
    const p = product

    let tags = []
    let visibleTags = []
    let extraTags = 0
    if (p.category) {
      tags = p.category.split(',')
      visibleTags = tags.slice(0, 3)
      extraTags = tags.length - visibleTags.length
    }

    return (
      <Paper>
        <div onClick={this.openProduct} className={cs(classes.vertical, classes.root)}>
          <div width="100%" className={classes.vertical}>
            <div
              className={classes.horizontal}
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: -10,
                marginLeft: -10,
                marginRight: -10,
                background: '#EEEEEE'
              }}
            >
              <div className={classes.vertical}>
                <Typography>{p.name}</Typography>
                <div className={classes.horizontal}>
                  {visibleTags.map(tag => (
                    <Chip
                      key={tag}
                      style={{ margin: 2, maxWidth: `calc(${100 / visibleTags.length}% - ${visibleTags.length > 2 ? 10 : 0}px)` }}
                      labelStyle={styles.chip}
                      label={tag}
                    />
                  ))}
                  {extraTags > 0 ? (
                    <Chip
                      backgroundColor="#888888"
                      style={{ margin: 2 }}
                      labelStyle={Object.assign({ color: '#FFFFFF' }, styles.chip)}
                      label={'+' + extraTags}
                    />
                  ) : null}
                </div>
              </div>
              {!p.needsSave ? (
                <IconButton
                  iconButtonElement={
                    <IconButton onTouchTap={this.handleMore} onClick={e => e.stopPropagation()} style={styles.icon} iconStyle={styles.icon2}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  useLayerForClickAway={true}
                  targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  <MenuItem onTouchTap={this.openProduct} primaryText={I18n.t('edicao_produto')} />
                  {!p.was_donated ? <MenuItem onTouchTap={this.props.donateProduct.bind(this, this)} primaryText={I18n.t('doar_produto')} /> : null}
                  <MenuItem onTouchTap={this.props.showRemoveDialog.bind(this, p.id)} primaryText={I18n.t('excluir_produto')} />
                </IconButton>
              ) : this.state.synchronizing ? (
                <CircularProgress size={24} />
              ) : (
                <SyncProblem color="primary" />
              )}
            </div>
            <div className={classes.horizontal}>
              <div className={classes.vertical}>
                <Paper style={styles.circle1}>
                  <Typography>{p.warrant_in_days}</Typography>
                </Paper>
                <Typography>{I18n.t('geral')}</Typography>
              </div>
              <div flexShrink={1} className={classes.vertical}>
                <Paper style={styles.circle2}>
                  <Typography>{p.extended_warrant_in_days}</Typography>
                </Paper>
                <Typography>{I18n.t('estendida')}</Typography>
              </div>
              <div className={classes.vertical}>
                <Paper style={styles.circle3}>
                  <Typography>{p.warrant_in_days + p.extended_warrant_in_days}</Typography>
                </Paper>
                <Typography>{I18n.t('total')}</Typography>
              </div>
              <div />
              <div width="100%" flexShrink={1} className={classes.vertical}>
                <Paper style={styles.circle4}>
                  <Add color="primary" style={p.insurance_in_days > 0 ? { display: 'none' } : {}} />
                  <Typography color="primary" style={p.insurance_in_days === 0 ? { display: 'none' } : {}}>
                    {p.insurance_in_days}
                  </Typography>
                </Paper>
                <Typography>{I18n.t('seguro')}</Typography>
              </div>
            </div>
            <Divider />
            {p.was_donated ? (
              <div className={classes.horizontal}>
                <Favorite style={{ width: 18, height: 18, marginRight: 5 }} color="error" />
                <Typography>{I18n.t('produto_doado')}</Typography>
              </div>
            ) : (
              <div className={classes.horizontal}>
                {this.state.daysDiffDate < 0 ? (
                  <Done style={{ width: 18, height: 18, marginRight: 5 }} color="primary" />
                ) : (
                  <History style={{ width: 18, height: 18, marginRight: 5 }} color="error" />
                )}
                <Typography>{this.labelDateText()}</Typography>
              </div>
            )}
          </div>
        </div>
      </Paper>
    )
  }
}
