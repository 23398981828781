import _objectWithoutProperties from "/Users/thiagolins/workspaces/react/novoto/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from 'react';
import { AppStore } from "../../stores";
import { Route, Redirect } from 'react-router-dom';

var PrivateRoute = function PrivateRoute(_ref) {
  var Component = _ref.component,
      _render = _ref.render,
      rest = _objectWithoutProperties(_ref, ["component", "render"]);

  return React.createElement(Route, _extends({}, rest, {
    render: function render(props) {
      // if (UserStore.logged) {
      if (AppStore.token) {
        if (_render) {
          return _render();
        } else {
          return React.createElement(Component, props);
        }
      } else {
        return React.createElement(Redirect, {
          to: {
            pathname: '/login',
            state: {
              from: props.location
            }
          }
        });
      }
    }
  }));
};

export default PrivateRoute;