import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

const styles = theme => ({
  boxMessage: {
    padding: 26,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1
  },
  message: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: 14
  },
  icon: {
    height: 'auto',
    width: 70,
    marginBottom: 8,
    fill: theme.palette.grey[400]
  }
})

export default
@withStyles(styles)
class NoInfoMessage extends React.Component {
  render() {
    const { classes, message } = this.props

    return (
      <div className={classes.boxMessage}>
        <ErrorOutline className={classes.icon} />
        <div className={classes.message}>{message}</div>
      </div>
    )
  }
}
