import React from 'react';
import { withStyles, Paper, Typography, Table, TableBody } from '@material-ui/core';

var styles = function styles(theme) {
  return {
    paper: {
      height: '100%',
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: 920,
      marginBottom: 16
    },
    header: {
      padding: 20,
      paddingBottom: 10
    }
  };
};

var ConfigSection = function ConfigSection(_ref) {
  var title = _ref.title,
      subtitle = _ref.subtitle,
      children = _ref.children,
      classes = _ref.classes;
  return React.createElement(Paper, {
    className: classes.paper
  }, React.createElement("div", {
    className: classes.header
  }, title && React.createElement(Typography, {
    variant: "h6"
  }, title), subtitle && React.createElement(Typography, {
    component: "p"
  }, subtitle)), React.createElement(Table, null, React.createElement(TableBody, null, children)));
};

export default withStyles(styles)(ConfigSection);