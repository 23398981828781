import React from 'react'
import { withStyles } from '@material-ui/core'
import { RealmStore, observer } from '@faicon/core'

const styles = theme => ({
  root: {
    height: '100%',
    padding: 10,
    boxSizing: 'border-box',
    objectFit: 'contain'
  }
})

export default
@withStyles(styles)
@observer
class IconClick extends React.Component {
  render() {
    const { classes } = this.props

    if (!RealmStore.remoteLogo) {
      return <div>...</div>
    }
    return (
      <img className={classes.root} src={RealmStore.remoteLogo} alt="Logo" />
    )
  }
}
