import React from 'react'
import { FormControlLabel, Radio, FormGroup, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

export default class ResponsiveSelect extends React.Component {
  render() {
    const { mobile, data, label, value, onChange, showEmptyOption, labelFunction, emptyLabel = 'Nenhum' } = this.props
    if (!mobile) {
      return (
        <FormGroup row value={value} onChange={onChange}>
          {data.map(item => (
            <FormControlLabel
              key={item.id}
              control={<Radio checked={value === item.id} />}
              label={labelFunction ? labelFunction(item) : item.name}
              value={String(item.id)}
            />
          ))}
        </FormGroup>
      )
    } else {
      return (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <Select value={value} onChange={onChange}>
            {showEmptyOption && <MenuItem value={0}>{emptyLabel}</MenuItem>}
            {data.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {labelFunction ? labelFunction(item) : item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
  }
}
