import React from 'react'
import { withStyles } from '@material-ui/core/styles'
//import Label from '../../atoms/Label' --------- CONSERTAR O ATOM DEPOIS
// import Input from '../../atoms/Input'
// import Paper from '../../atoms/Paper'

const styles = theme => ({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    paddingLeft: 15,
    backgroundColor: theme.palette.grey[300]
  },

  label: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.grey[700]
  }
})

const Subheader = ({ classes, title, disabledLabel, ...props }) => (
  <div className={classes.root}>
    <span className={classes.label}>{title}</span>
  </div>
)

export default withStyles(styles)(Subheader)
