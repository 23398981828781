import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Fab } from '@material-ui/core'
import { Notification, Input } from 'draft'

const styles = theme => ({
  buttons: {
    display: 'flex',
    width: '100%'
  },
  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
    boxShadow: 'none'
  }
})

export default
@withStyles(styles)
class NotificationConfirm extends React.Component {
  state = {
    feedback: '',
    error: false,
    errorMessage: ''
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value })
  }

  handleClick = feedback => {
    if (!feedback) {
      this.setState({
        error: true,
        errorMessage: 'Preencha o campo de feedback'
      })
      return false
    }
    this.props.onConfirm(feedback)
  }

  render() {
    const { classes, anonymous, title, description, options } = this.props
    const { feedback, error, errorMessage } = this.state

    let placeholder = 'Insira sua resposta'
    let confirmLabel = 'Ok'
    if (options) {
      placeholder = options.placeholder
      confirmLabel = options.confirmLabel
    }

    return (
      <Notification isAnonymous={anonymous} title={title} description={description}>
        <div className={classes.buttons}>
          <Input
            name="phone"
            placeholder={placeholder}
            value={feedback}
            fullWidth
            onChange={this.handleChange('feedback')}
            error={error}
            helperText={errorMessage}
          />
          <Fab onClick={() => this.handleClick(feedback)} color="secondary" size="small" className={classes.button}>
            {confirmLabel}
          </Fab>
        </div>
      </Notification>
    )
  }
}
