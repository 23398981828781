function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from 'react';
import TextElement from "./../elements/TextElement";
export default (function (props) {
  return React.createElement(TextElement, _extends({}, props, {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    digits: 8,
    type: "tel",
    pattern: "\\d{5}-\\d{3}"
  }));
});