import React from 'react'
import { Modal, Paper, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  modalBox: {
    width: '100%',
    maxWidth: 350,
    padding: 10
  },
  modalBoxRegister: {
    padding: 20
  },
  modalHeader: {
    padding: '8px 0',
    fontSize: 13,
    marginBottom: 10,
    textAlign: 'center'
  },
  buttonOption: {
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  ou: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    margin: '8px 0'
  }
})

export default
@withStyles(styles)
class ModalYesNot extends React.Component {
  render() {
    const { classes, message, open, onBackdropClick, onClickYes, onClickNot, yesText = 'Sim', notText = 'Não', ...props } = this.props
    return (
      <Modal className={classes.modal} open={open} disableAutoFocus={true} onBackdropClick={onBackdropClick} {...props}>
        <Paper elevation={4} className={`${classes.modalBox} ${classes.modalBoxRegister}`}>
          <div className={classes.modalHeader}>{message ? message : 'Insira algo na prop message para ser exibido aqui.'}</div>
          <div>
            <Button fullWidth variant="outlined" color="secondary" className={classes.buttonOption} onClick={onClickYes}>
              {yesText}
            </Button>
            <span className={classes.ou}>ou</span>
            <Button fullWidth variant="outlined" className={classes.buttonOption} onClick={onClickNot}>
              {notText}
            </Button>
          </div>
        </Paper>
      </Modal>
    )
  }
}
