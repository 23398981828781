import React from 'react'
import { withStyles, Paper, Grid, Typography } from '@material-ui/core'

const styles = theme => ({
  paper: {
    padding: 20
  },
  extra: theme.typography.body2
})

const Divider = ({ title, subtitle, rightTitle, extra, children, paper = true, padding = true, classes }) => {
  let Container = Paper
  if (!paper) {
    Container = props => <div {...props} />
  }

  return (
    <Grid item xs={12} sm={12}>
      <Container className={padding ? classes.paper : undefined}>
        <Grid container spacing={2}>
          {(title || subtitle) && (
            <>
              <Grid item xs={12} sm={rightTitle ? 8 : 12}>
                {title && <Typography variant="h6">{title}</Typography>}
                {subtitle && <Typography component="p">{subtitle}</Typography>}
              </Grid>
              {rightTitle && (
                <Grid item xs={12} sm={4} align="right">
                  {rightTitle}
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} sm={12}>
            {children}
          </Grid>

          {extra && (
            <Grid item xs={12} sm={12} className={classes.extra}>
              {extra}
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  )
}

export default withStyles(styles)(Divider)
