import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({})

const Img = ({ classes, source, ...props }) => {
  return <img {...props} src={source} alt={`imagem ${source}`} />
}

Img.propTypes = {
  classes: PropTypes.object,
  source: PropTypes.string.isRequired
}

export default withStyles(styles)(Img)
