import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from './../../molecules/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  button: {
    textTransform: 'none'
  }
})

@withStyles(styles)
class ConfirmDialog extends React.Component {
  state = {
    open: false
  }

  open = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  handleConfirm = () => {
    this.setState({ open: false })
    this.props.onConfirm()
  }

  handleDisagree = () => {
    this.handleClose()
    this.props.onDisagree && this.props.onDisagree()
  }

  render() {
    const { classes, message, agreeButtonMessage, disagreeButtonMessage, onConfirm, onDisagree, children, onClose, ...props } = this.props

    return (
      <Dialog
        {...props}
        content={!children && <DialogContentText id="alert-dialog-description">{message}</DialogContentText>}
        actions={
          <React.Fragment>
            <Button className={classes.button} onClick={this.handleDisagree} color="secondary">
              {disagreeButtonMessage || 'Não'}
            </Button>
            <Button className={classes.button} onClick={this.handleConfirm} color="secondary" autoFocus>
              {agreeButtonMessage || 'Sim'}
            </Button>
          </React.Fragment>
        }
      >
        {children}
      </Dialog>
    )
  }
}

export default ConfirmDialog
