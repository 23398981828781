import React from 'react'
import Highcharts from 'highcharts'
import noData from 'highcharts/modules/no-data-to-display'
import { IconButton, withStyles } from '@material-ui/core'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'

noData(Highcharts)

const styles = theme => ({
  miniIcon: {
    width: 18,
    height: 18
  },
  miniButton: {
    width: 30,
    height: 30,
    flexShrink: 0,
    padding: 0
  },
  controlBar: {
    height: 50,
    zIndex: 1,
    // alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center'
  },
  periodIndication: {
    padding: 10
  }
})

export default
@withStyles(styles)
class PeriodNavigator extends React.Component {
  render() {
    // console.log(this.props.showPeriodIndication)

    const {
      onBack,
      onNext,
      classes,
      currentSelection,
      currentLabel
    } = this.props
    return (
      <div className={classes.controlBar}>
        {onBack && (
          <IconButton
            className={classes.miniButton}
            onClick={this.props.onBack}
          >
            <ArrowBack className={classes.miniIcon} />
          </IconButton>
        )}
        <span className={classes.periodIndication}>
          {currentSelection || currentLabel || 'Mês atual'}
        </span>
        {onNext && (
          <IconButton
            className={classes.miniButton}
            onClick={this.props.onNext}
          >
            <ArrowForward className={classes.miniIcon} />
          </IconButton>
        )}
      </div>
    )
  }
}
