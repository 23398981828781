import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Dialog } from 'draft'
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined'

const style = theme => ({
  dialog: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
  boxMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%'
  },
  primaryMessage: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 26,
    flex: 2,
    minHeight: 200,
    boxSizing: 'border-box',
    boxShadow: '0px 1px 6px rgba(0,0,0,.6)',
    zIndex: 1
  },
  primaryMessageAlone: {
    background: theme.palette.common.white,
    flex: 1
  },
  message: {
    padding: '0 26px',
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.primary.contrastText
  },
  messageAlone: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  icon: {
    height: 'auto',
    width: 70,
    marginBottom: 8,
    fill: theme.palette.primary.contrastText
  },
  iconAlone: {
    width: 100,
    fill: theme.palette.primary.main
  },
  boxSubMessage: {
    background: theme.palette.common.white,
    flex: 3,
    padding: 26,
    display: 'flex',
    alignItems: 'center'
  },
  subMessage: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: 14,
    width: '100%'
  }
})

export default
@withStyles(style)
class SuccessMessage extends React.Component {
  render() {
    const { classes, message = 'Sucesso!', onClick, icon = true, open = false, children, messageButton = 'OK', ...props } = this.props

    return (
      <Dialog
        className={classes.main}
        contentClass={classes.dialog}
        {...props}
        content={
          <div className={classes.boxMain}>
            <div className={`${classes.primaryMessage} ${!children && classes.primaryMessageAlone}`}>
              {icon && <CheckCircleOutlined className={`${classes.icon} ${!children && classes.iconAlone}`} />}
              <div className={`${classes.message} ${!children && classes.messageAlone}`}>{message}</div>
            </div>
            {children && (
              <div className={classes.boxSubMessage}>
                <div className={classes.subMessage}>{children}</div>
              </div>
            )}
          </div>
        }
        actions={
          <Button onClick={onClick} color="secondary" autoFocus style={{ textTransform: 'none' }}>
            {messageButton}
          </Button>
        }
        open={open}
      />
    )
  }
}
