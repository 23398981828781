import _objectSpread from "/Users/thiagolins/workspaces/react/novoto/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { observe } from 'mobx';
export default (function (history, store) {
  // Initialise store
  store.history = history; // Handle update from history object

  var handleLocationChange = function handleLocationChange(location) {
    store._updateLocation(location);
  };

  var unsubscribeFromHistory = history.listen(handleLocationChange);
  handleLocationChange(history.location);

  var subscribe = function subscribe(listener) {
    var onStoreChange = function onStoreChange(change) {
      var rawLocation = _objectSpread({}, store.location);

      listener(rawLocation, history.action);
    }; // Listen for changes to location state in store


    var unsubscribeFromStore = observe(store, 'location', onStoreChange);
    listener(store.location, history.action);
    return function () {
      unsubscribeFromStore();
    };
  };

  var unsubscribe = function unsubscribe() {
    return unsubscribeFromHistory();
  };

  history.subscribe = subscribe;
  history.unsubscribe = unsubscribe;
  return history;
});