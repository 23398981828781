import React from 'react'
import { withStyles } from '@material-ui/core'
//import Label from '../../atoms/Label' --------- CONSERTAR O ATOM DEPOIS
import HDivider from '../../atoms/HDivider'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
  },

  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    padding: 10
  },

  headerLabel: {
    color: 'white',
    fontSize: 36,
    fontWeight: 500
  },

  textContainer: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
  },

  reminderLabel: {
    fontSize: 16,
    fontWeight: 500
  },
  reminder: {
    fontSize: 16,
    fontWeight: 400
  },

  divider: {
    margin: '15px 0'
  },

  infoLabel: {
    fontSize: 16,
    fontWeight: 500
  },
  info: {
    color: '#C30000',
    fontSize: 16,
    fontWeight: 400
  }
})

const Success = ({ classes, reminder, info, tipTitle, tipColor }) => (
  <div className={classes.root}>
    <div className={classes.header}>
      <label className={classes.headerLabel}>Sucesso!</label>
    </div>
    <div className={classes.textContainer}>
      <label className={classes.reminderLabel}>Parabéns!</label>
      <label className={classes.reminder}>{reminder}</label>
    </div>
    <HDivider isDefault className={classes.divider} />
    <div className={classes.textContainer}>
      <label className={classes.infoLabel} style={{ color: tipColor }}>
        {tipTitle}
      </label>
      <label className={classes.info}>{info}</label>
    </div>
  </div>
)

export default withStyles(styles)(Success)
