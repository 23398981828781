import React from 'react'
import { Checkbox as BaseCheckbox, FormControlLabel, withStyles, Divider } from '@material-ui/core'

const styles = theme => ({
  divider: {
    marginLeft: 32
  }
})

const Checkbox = ({ classes, label, value, className, inputref, ...props }) => (
  <React.Fragment>
    <Divider className={classes.divider} />
    <FormControlLabel value={value} className={className} control={<BaseCheckbox {...props} />} label={label} />
  </React.Fragment>
)

export default withStyles(styles)(Checkbox)
