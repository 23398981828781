import _classCallCheck from "/Users/thiagolins/workspaces/react/novoto/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";

var ErrorObject = function ErrorObject(error) {
  _classCallCheck(this, ErrorObject);

  this.requestResponse = void 0;
  this.error = void 0;

  if (error instanceof Error) {
    console.error(error);
    this.requestResponse = null;
    this.error = error;
  } else if (typeof error === 'string') {
    this.requestResponse = null;
    this.error = error;
  } else {
    this.requestResponse = error.requestResponse;
    this.error = error.error;
  }
};

export { ErrorObject as default };