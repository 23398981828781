import _classCallCheck from "/Users/thiagolins/workspaces/react/novoto/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";

var ResourceLoader = function ResourceLoader() {
  _classCallCheck(this, ResourceLoader);

  this.load = function (path) {
    return "".concat(process.env.REACT_APP_HOST, "/bucket/").concat(path);
  };
};

export default new ResourceLoader();