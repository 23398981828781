import axios from 'axios';
import { forEach, isNull, merge } from 'lodash';
import qs from 'qs';
import { AppStore } from "./../";

function ajaxOptions(url, options) {
  var baseOptions = {
    url: url,
    method: options.method,
    responseType: options.responseType || 'json'
  };
  if (options.headers) baseOptions.headers = options.headers;

  if (options.method === 'GET' && options.data) {
    var sendObject = {};
    Object.keys(options.data).forEach(function (key) {
      if (options.data[key]) {
        sendObject[key] = JSON.stringify(options.data[key]).replace(/^"/, '').replace(/"$/, '');
      } else {
        sendObject[key] = null;
      }
    });
    url = "".concat(url, "?").concat(qs.stringify(sendObject, options.qs));
    return Object.assign({}, baseOptions, {
      url: url
    });
  }

  var formData = new FormData();
  var hasFile = false;
  forEach(options.data, function (val, attr) {
    hasFile = hasFile || val instanceof File || val instanceof Blob;
    if (!isNull(val)) formData.append(attr, val);
  });

  if (hasFile) {
    return Object.assign({}, baseOptions, {
      cache: false,
      processData: false,
      data: formData
    });
  }

  return Object.assign({}, baseOptions, {
    data: options.data
  });
}

function ajax(url, options) {
  var CancelToken = axios.CancelToken;
  var source = CancelToken.source();
  var xhr = axios(Object.assign({}, ajaxOptions(url, options), {
    cancelToken: source.token
  }));
  var promise = new Promise(function (resolve, reject) {
    xhr.then(function (response) {
      return resolve(response.data);
    }).catch(function (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled');
      } else if (error.message === 'Network Error') {
        error.message = 'Verifique sua conexão com a internet e tente novamente.';
        return reject(error);
      } else {
        return reject(error && error.response && error.response.data || error);
      }
    });
  }).catch(function (err) {
    // console.log(err) // Aqui posso salvar todos os erros em algum lugar
    if (err && err.error && (err.error.code === 'AUTHORIZATION_REQUIRED' || err.error.code === 'INVALID_TOKEN')) {
      AppStore.invalidateToken();
    }

    return Promise.reject(err);
  });

  var abort = function abort() {
    source.cancel();
  };

  return {
    abort: abort,
    promise: promise
  };
}

export default (function () {
  return {
    apiPath: '',
    commonOptions: {},
    get: function get(path, data) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return ajax("".concat(this.apiPath).concat(path), merge({}, {
        method: 'GET',
        data: data
      }, this.commonOptions, options));
    },
    post: function post(path, data) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return ajax("".concat(this.apiPath).concat(path), merge({}, {
        method: 'POST',
        data: data
      }, this.commonOptions, options));
    },
    put: function put(path, data) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return ajax("".concat(this.apiPath).concat(path), merge({}, {
        method: 'PUT',
        data: data
      }, this.commonOptions, options));
    },
    patch: function patch(path, data) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return ajax("".concat(this.apiPath).concat(path), merge({}, {
        method: 'PATCH',
        data: data
      }, this.commonOptions, options));
    },
    del: function del(path) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return ajax("".concat(this.apiPath).concat(path), merge({}, {
        method: 'DELETE'
      }, this.commonOptions, options));
    }
  };
});