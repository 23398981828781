import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField as BaseTextField } from '@material-ui/core'

const styles = theme => ({})

const TextField = ({ classes, onChange, value, ...props }) => {
  return (
    <BaseTextField
      {...props}
      value={value || ''}
      onChange={e => onChange && onChange(e.target.value, e.target.name)}
      fullWidth
      pattern="[A-Za-z0-9]{1,20}"
      InputLabelProps={{
        shrink: true
      }}
      classes={styles.textField}
    />
  )
}

export default withStyles(styles)(TextField)
