import React from 'react'
import Grid from './Grid'
import Form from './Form'
import { Router } from '@faicon/core'

export default () => (
  <Router
    routes={[
      { path: '/add', component: Form },
      { path: '/:id', component: Form },
      { path: '/', component: Grid }
    ]}
  />
)
