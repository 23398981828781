import React from 'react'
import { AppStore } from '@faicon/core'
import { withStyles, Paper } from '@material-ui/core'
import cs from 'classnames'

const styles = theme => ({
  main: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  inner: {
    width: '100%',
    maxWidth: 960,
    paddingBottom: 56
  },
  toolbar: {
    left: 0,
    justifyContent: 'center',
    marginLeft: AppStore.menuFixed ? 250 : 69,
    width: AppStore.menuFixed ? 'calc(100% - 270px)' : 'calc(100% - 90px)',
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    padding: 10,
    height: 36
  },
  innerToolbar: {
    width: '100%',
    display: 'flex',
    maxWidth: 960
  },
  rightToolbar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  childLeft: {
    marginRight: 10
  },
  childRight: {
    marginLeft: 10
  }
})

class Center extends React.Component {
  fragmentOrChild = prop => {
    if (prop.type.toString() === React.Fragment.toString()) {
      return prop.props.children
    } else {
      return [prop]
    }
  }

  render() {
    const { className, rightButtons, leftButtons, classes, vertical, ...props } = this.props
    return (
      <div className={classes.main}>
        <div className={cs(className, classes.inner)} {...props} />
        {(rightButtons || leftButtons) && (
          <Paper className={classes.toolbar}>
            <div className={classes.innerToolbar}>
              {leftButtons && (
                <div>
                  {React.Children.map(this.fragmentOrChild(leftButtons), child =>
                    React.cloneElement(child, {
                      className: cs(classes.childLeft, child.className)
                    })
                  )}
                </div>
              )}
              {rightButtons && (
                <div className={classes.rightToolbar}>
                  {React.Children.map(this.fragmentOrChild(rightButtons), child =>
                    React.cloneElement(child, {
                      className: cs(classes.childRight, child.className)
                    })
                  )}
                </div>
              )}
            </div>
          </Paper>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Center)
