import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper } from '@material-ui/core'
import Divider from '../../atoms/Divider'
import { AppStore } from '@faicon/core'

const styles = theme => ({
  root: {
    marginBottom: 10,
    marginTop: -20,
    padding: 10,
    display: 'flex',
    position: 'fixed',
    width: `calc(100% - ${AppStore.menuWidth + 40}px)`,
    zIndex: 10,
    // height: 55,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: -10,
      marginLeft: -10,
      marginRight: -10,
      width: '100%'
    }
  },
  spacer: {
    height: 60
  }
})

const Toolbar = ({ classes, children }) => {
  return (
    <>
      <Paper elevation={6} className={classes.root} square>
        {React.Children.map(children, child => child)
          .reduce((prev, curr, index) => {
            if (!curr) {
              return prev
            } else {
              return prev.concat([curr, <Divider key={index} />])
            }
          }, [])
          .slice(0, -1)}
      </Paper>
      <div className={classes.spacer} />
    </>
  )
}

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default withStyles(styles)(Toolbar)
