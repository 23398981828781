import React from 'react'
import { withStyles, IconButton } from '@material-ui/core'
import { UserStore } from 'stores'
import { observer, inject, DataGridFetch, DataGridColumn, DateFormatter } from '@faicon/core'
import { Span } from 'components'

const styles = theme => ({
  root: { flex: 1 },
  iconButton: {
    padding: 8
  }
})

export default
@withStyles(styles)
@inject('router')
@observer
class Users extends React.Component {
  state = {}

  handleAdd = () => {
    this.props.router.pushSubpage('add')
  }

  handleClick = id => this.props.router.pushSubpage(id)

  render() {
    const { classes } = this.props

    return (
      <Span className={classes.root}>
        <DataGridFetch
          //  onItemClick={this.handleClick}
          store={UserStore}
          searchableFields={['name', 'clientCpf', 'sellerCpf']}
          orderBy="id"
          order="asc"
        >
          <DataGridColumn title="Código" field="id" />
          <DataGridColumn title="Nome" field="name" />
          <DataGridColumn title="E-mail" field="email" />
          {/* <DataGridColumn title="" field="emailVerified" /> */}
          {/* <DataGridColumn title="" field="verificationToken" /> */}
          <DataGridColumn title="Habilitado" field="enabled" />
          {/* <DataGridColumn title="" field="salt" /> */}
          <DataGridColumn title="Profissão" field="occupation.name" />
          <DataGridColumn title="Gênero" field="gender" />
          <DataGridColumn title="Aniversário" field="birthdate" formatter={DateFormatter} />
          <DataGridColumn title="Estado civil" field="civilstatus" />
          <DataGridColumn title="Celular" field="mobile" />
          <DataGridColumn title="Telefone" field="phone" />
          <DataGridColumn title="CPF" field="cpf" />
          {/* <DataGridColumn title="" field="notificationsEnabled" /> */}
          <DataGridColumn title="CEP" field="postalCode" />
          <DataGridColumn title="Rua" field="street" />
          <DataGridColumn title="Número" field="number" />
          <DataGridColumn title="Complemento" field="complement" />
          <DataGridColumn title="Bairro" field="district" />
          <DataGridColumn title="Cidade" field="city" />
          <DataGridColumn title="Estado" field="state" />
          <DataGridColumn title="País" field="country" />
          {/* <DataGridColumn title="" field="locale" /> */}
          {/* <DataGridColumn title="" field="notificationsThirtyDays" /> */}
          {/* <DataGridColumn title="" field="notificationsEmail" /> */}
          <DataGridColumn title="Data de cadastro" field="created" formatter={DateFormatter} />
          {/* <DataGridColumn title="" field="modified" /> */}
          <DataGridColumn
            width={100}
            render={(value, field, row) => (
              <IconButton className={classes.iconButton} onClick={() => this.handleClick} alt="Editar">
                <i className="material-icons">edit</i>
              </IconButton>
            )}
          />
        </DataGridFetch>
      </Span>
    )
  }
}
