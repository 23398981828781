import React from 'react'
import { withStyles, GridList, Button, Fab, Divider, InputBase, Paper, IconButton } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
// import MenuIcon from '@material-ui/icons/Menu'
import { ProductStore1 } from 'stores'
import { observer, inject, UserStore, I18n } from '@faicon/core'
import ProductRenderer from './ProductRenderer'
import { ModalBusy } from 'components'
import { EmptyMessage, ConfirmDialog } from 'draft'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  list: { flex: 1, paddingBottom: 40, width: '100%' },
  emptyContainer: {
    textAlign: 'center'
  },
  emptyButton: {
    marginTop: 20
  },
  margin: {
    zIndex: 100,
    position: 'fixed',
    bottom: 20,
    right: 10
  },
  extendedIcon: {
    color: theme.palette.common.white
  },
  serachContainer: {
    width: '100%',
    maxWidth: 400,
    display: 'flex'
  },
  paper: {
    flex: 1,
    boxSizing: 'border-box',
    padding: '2px 4px',
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
})

export default
@withStyles(styles)
@inject('router')
@observer
class MyProduct extends React.Component {
  state = {
    open: false,
    donate: false,
    size: document.documentElement.clientWidth,
    search: ''
  }

  componentDidMount() {
    this.refresh()
    window.addEventListener('resize', this.refreshSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshSize)
  }

  refreshSize = () => {
    this.setState({ size: document.documentElement.clientWidth })
  }

  refresh = force => {
    if (ProductStore1.isEmpty || force) {
      ProductStore1.scope()
        .where({ userId: UserStore.logged.id, removedAt: null }, true)
        .order('id desc')
        .all()
    }
  }

  donate = () => {
    ProductStore1.workingModel.set({ wasDonated: true })
    ProductStore1.workingModel.save().then(() => {
      this.handleClose()
      this.refresh(true)
    })
  }

  remove = () => {
    ProductStore1.workingModel.set({ removedAt: new Date() })
    ProductStore1.workingModel.save().then(() => {
      this.handleClose()
      this.refresh(true)
    })
  }

  showRemoveDialog = productId => {
    ProductStore1.init(ProductStore1.get(productId).toJS())
    this.setState({ open: true })
  }

  donateProduct = productId => {
    ProductStore1.init(ProductStore1.get(productId).toJS())
    this.setState({ donate: true })
  }

  handleClose = () => {
    this.setState({ donate: false, open: false })
  }

  addProduct = () => {
    this.props.router.pushSubpage('add')
  }

  editProduct = productId => {
    this.props.router.pushSubpage(productId)
  }

  handleSearch = e => {
    this.setState({ search: e.target.value || '' })
  }

  render() {
    const { classes } = this.props
    const { open, donate, size, search } = this.state

    if (ProductStore1.busy()) {
      return <ModalBusy busy={true} />
    }

    if (ProductStore1.models.length === 0) {
      return (
        <EmptyMessage
          content={
            <div className={classes.emptyContainer}>
              {/* TODO: traduzir */}
              <div>{I18n.t('sem_produtos')}</div>
              <Button className={classes.emptyButton} color="primary" onClick={this.addProduct} size="small">
                {/* TODO: traduzir */}
                {I18n.t('primeiro_produto')}
              </Button>
            </div>
          }
        />
      )
    }

    let productsList = ProductStore1.models
    if (search.length > 0) {
      productsList = productsList.filter(
        product =>
          String(product.g('name'))
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0 ||
          String(product.g('category'))
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
      )
    }

    return (
      <div className={classes.container}>
        <Fab variant="extended" size="medium" color="secondary" className={classes.margin} onClick={this.addProduct}>
          <Add className={classes.extendedIcon} />
          {I18n.t('adicionar')}
        </Fab>
        <div className={classes.serachContainer}>
          <Paper className={classes.paper}>
            {/* <IconButton className={classes.iconButton}>
              <MenuIcon />
            </IconButton> */}
            <IconButton className={classes.iconButton}>
              <SearchIcon />
            </IconButton>
            <InputBase className={classes.input} placeholder={I18n.t('pesquisar')} value={search} onChange={this.handleSearch} />
            <Divider className={classes.divider} />
            <IconButton color="secondary" className={classes.iconButton} onClick={this.handleSearch}>
              <Clear />
            </IconButton>
          </Paper>
        </div>
        <GridList className={classes.list} cellHeight="auto" cols={Math.round(size / 400)}>
          {productsList.map(product => (
            <ProductRenderer
              key={product.id}
              product={product.toJS()}
              onDonate={this.donateProduct}
              onRemove={this.showRemoveDialog}
              onEdit={this.editProduct}
            />
          ))}
        </GridList>
        <ConfirmDialog
          open={open}
          fullScreen={false}
          onConfirm={this.remove}
          onClose={this.handleClose}
          title={I18n.t('excluir_produto')}
          message={I18n.t('confirma_exclusao_produto')}
          agreeButtonMessage={I18n.t('excluir')}
          disagreeButtonMessage={I18n.t('cancelar')}
        />
        <ConfirmDialog
          open={donate}
          fullScreen={false}
          onConfirm={this.donate}
          onClose={this.handleClose}
          title={I18n.t('doar_produto')}
          message={I18n.t('confirma_doacao_produto')}
          agreeButtonMessage={I18n.t('doar')}
          disagreeButtonMessage={I18n.t('cancelar')}
        />
      </div>
    )
  }
}
