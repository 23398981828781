import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Notification } from 'draft'

const styles = theme => ({
  buttons: {
    display: 'flex'
  },
  button: {
    margin: 6,
    textTransform: 'none'
  }
})

export default
@withStyles(styles)
class NotificationConfirm extends React.Component {
  handleClick = action => {
    this.props.onConfirm(action)
  }

  render() {
    const { classes, isAnonymous, title, description, options } = this.props
    let denyLabel = 'Não'
    let confirmLabel = 'Sim'
    if (options) {
      denyLabel = options.denyLabel
      confirmLabel = options.confirmLabel
    }

    return (
      <Notification isAnonymous={isAnonymous} title={title} description={description}>
        <div className={classes.buttons}>
          <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.handleClick('deny')}>
            {denyLabel}
          </Button>
          <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.handleClick('confirm')}>
            {confirmLabel}
          </Button>
        </div>
      </Notification>
    )
  }
}
