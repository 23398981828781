import React from 'react'
import { withStyles, ListItem } from '@material-ui/core'
import { ResourceLoader } from '@faicon/core'

const styles = theme => ({
  main: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    width: 'auto',
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[400]
    }
  },

  info: {
    display: 'flex',
    flexDirection: 'column'
  },

  img: {
    objectFit: 'contain',
    padding: 0,
    marginRight: 10,
    height: 60,
    width: 60
  },

  name: {
    fontWeight: 400,
    fontSize: 16
  },
  description: {
    fontWeight: 300,
    fontSize: 14
  }
})

export default
@withStyles(styles)
class Award extends React.Component {
  resolveIncents = minCost => {
    if (minCost < 2) {
      return 'Incent'
    } else {
      return 'Incents'
    }
  }

  render() {
    const { classes, picture, name, minCost, action, alt } = this.props

    return (
      <ListItem button className={classes.main} onClick={action}>
        <img className={classes.img} alt={alt} src={ResourceLoader.load(picture)} />

        <div className={classes.info}>
          <label className={classes.name}>{name}</label>
          <label className={classes.description}>
            Troque a partir de {minCost} {this.resolveIncents(minCost)}
          </label>
        </div>
      </ListItem>
    )
  }
}
