import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'

const styles = theme => ({})

const PaperAtom = ({ classes, ...props }) => {
  return (
    <Paper elevation={0} {...props}>
      {props.children}
    </Paper>
  )
}

PaperAtom.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PaperAtom)
