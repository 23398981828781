import React from 'react'
import { withStyles, Grid, Button } from '@material-ui/core'
import { observer, I18n, TextField, inject, UserStore } from '@faicon/core'
import { Center, FormSection, Dropfile, ModalBusy } from 'components'
import { ProductStore1 } from 'stores'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

const styles = theme => ({})

export default
@withStyles(styles)
@inject('router')
@observer
class ProductAdd extends React.Component {
  state = {
    errorUpload: null
  }

  componentDidMount() {
    this.refresh()
  }

  componentWillUnmount() {
    if (ProductStore1.length === 1) {
      ProductStore1.reset([])
    }
  }

  handleChangeValue = name => value => {
    ProductStore1.workingModel.set({
      [name]: value
    })
  }

  handleSave = () => {
    ProductStore1.workingModel.set({ userId: UserStore.logged.id })
    ProductStore1.workingModel.save().then(() => {
      ProductStore1.reset([])
      this.props.router.pushBack()
    })
  }

  handleChange = name => e => {
    ProductStore1.workingModel.set({
      [name]: e.target.value
    })
  }

  refresh = (force = false) => {
    if (this.props.match.params.id) {
      if (ProductStore1.isEmpty || force) {
        ProductStore1.scope()
          .where({ id: this.props.match.params.id })
          .all()
          .then(() => (ProductStore1.workingModel = ProductStore1.get(this.props.match.params.id)))
      } else {
        ProductStore1.workingModel = ProductStore1.get(this.props.match.params.id)
      }
    } else {
      ProductStore1.init({ warrantInDays: 12, extendedWarrantInDays: 0, insuranceInDays: 0, price: 0, boughtAt: new Date() })
    }
  }

  render() {
    // const { classes } = this.props
    const { errorUpload } = this.state

    if (!ProductStore1.workingModel) {
      return <span>...</span>
    }

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-BR">
        <Center>
          <Grid container spacing={2}>
            <FormSection title={I18n.t('titulo_produto')} subtitle={I18n.t('subtitulo_produto')}>
              <TextField
                placeholder={I18n.t('exemplo_produto')}
                value={ProductStore1.workingModel.g('name')}
                onChange={this.handleChange('name')}
                fullWidth
                margin="dense"
              />
            </FormSection>
            <FormSection title={I18n.t('foto_nota')}>
              <Dropfile
                imageOnly
                value={ProductStore1.workingModel.g('invoicePath')}
                onUploadFinish={this.handleChangeValue('invoicePath')}
                error={errorUpload}
                label={I18n.t('solte_ou_clique')}
              />
            </FormSection>
            <FormSection title={I18n.t('foto_produto')}>
              <Dropfile
                imageOnly
                value={ProductStore1.workingModel.g('productPath')}
                onUploadFinish={this.handleChangeValue('productPath')}
                error={errorUpload}
                label={I18n.t('solte_ou_clique')}
              />
            </FormSection>

            <FormSection title={I18n.t('dados_compra')}>
              <KeyboardDatePicker
                label={I18n.t('data_compra')}
                format="DD/MM/YYYY"
                value={ProductStore1.workingModel.g('boughtAt') ? moment.utc(new Date(ProductStore1.workingModel.g('boughtAt'))).format('YYYY-MM-DD') : ''}
                onChange={this.handleChangeValue('boughtAt')}
                fullWidth
                invalidDateMessage={I18n.t('data_invalida')}
                InputLabelProps={{
                  shrink: true
                }}
                margin="dense"
              />
              <TextField
                label={I18n.t('valor')}
                value={ProductStore1.workingModel.g('price')}
                onChange={this.handleChange('price')}
                type="number"
                inputProps={{
                  step: '0.01',
                  lang: 'pt-BR'
                }}
                fullWidth
                margin="dense"
              />
              <TextField
                label={I18n.t('loja')}
                value={ProductStore1.workingModel.g('storeName')}
                onChange={this.handleChange('storeName')}
                fullWidth
                margin="dense"
              />
            </FormSection>

            <FormSection title={I18n.t('dados_garantia')}>
              <TextField
                label={I18n.t('garantia_geral') + ' (' + I18n.t('meses') + ')'}
                value={ProductStore1.workingModel.g('warrantInDays')}
                onChange={this.handleChange('warrantInDays')}
                fullWidth
                type="number"
                margin="dense"
              />
            </FormSection>

            <FormSection title={I18n.t('garantia_estendida')}>
              <TextField
                label={I18n.t('garantia_estendida') + ' (' + I18n.t('meses') + ')'}
                value={ProductStore1.workingModel.g('extendedWarrantInDays')}
                onChange={this.handleChange('extendedWarrantInDays')}
                fullWidth
                type="number"
                margin="dense"
              />
            </FormSection>

            <FormSection title={I18n.t('seguro')}>
              <TextField
                label={I18n.t('seguro') + ' (' + I18n.t('meses') + ')'}
                value={ProductStore1.workingModel.g('insuranceInDays')}
                onChange={this.handleChange('insuranceInDays')}
                fullWidth
                type="number"
                margin="dense"
              />
              <TextField
                label={I18n.t('numero_seguro')}
                value={ProductStore1.workingModel.g('insuranceNumber')}
                onChange={this.handleChange('insuranceNumber')}
                fullWidth
                margin="dense"
              />
              <TextField
                label={I18n.t('valor_seguro')}
                value={ProductStore1.workingModel.g('insurancePrice')}
                onChange={this.handleChange('insurancePrice')}
                fullWidth
                pattern="\d+(,\d{2})?"
                margin="dense"
              />
            </FormSection>

            <FormSection title={I18n.t('informacoes_adicionais')}>
              <TextField
                label={I18n.t('observacoes')}
                value={ProductStore1.workingModel.g('note')}
                onChange={this.handleChange('note')}
                fullWidth
                multiline
                margin="dense"
              />
              <TextField
                label={I18n.t('serial')}
                value={ProductStore1.workingModel.g('serial')}
                onChange={this.handleChange('serial')}
                fullWidth
                margin="dense"
              />
            </FormSection>

            <Grid xs={12} justify="flex-end" item container>
              <Button variant="contained" type="submit" color="secondary" onClick={this.handleSave}>
                {I18n.t('salvar')}
              </Button>
            </Grid>
          </Grid>
          <ModalBusy busy={ProductStore1.workingModel.busy()} />
        </Center>
      </MuiPickersUtilsProvider>
    )
  }
}
