import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'



const styles = theme => ({    
    
})

const PersonalData = ({ classes, data, iconStart, iconEnd, action, division, ...props }) => {
    return (
        <React.Fragment>  
                <ListItem onClick={action}>
                    <ListItemIcon>
                        {iconStart}
                    </ListItemIcon>
                    <ListItemText primary={data} />
                    <ListItemSecondaryAction>
                        {iconEnd}
                    </ListItemSecondaryAction>
                </ListItem>
                        {division}                
        </React.Fragment>
    )
}

export default withStyles(styles)(PersonalData)