import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Fab, Button } from '@material-ui/core'
import { Notification } from 'draft'

const styles = theme => ({
  buttons: {
    display: 'flex'
  },
  fab: {
    textTransform: 'none',
    margin: '0 5px'
  },
  confirmButton: {
    textTransform: 'none',
    marginTop: 20
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14,
    margin: '10px 0 5px 0'
  }
})

export default
@withStyles(styles)
class NotificaTionRating extends React.Component {
  state = {
    selected: null,
    error: false
  }

  handleClick = selectedValue => {
    if (!selectedValue) {
      this.setState({ error: true })
      return false
    }
    this.props.onConfirm(selectedValue)
  }

  handleSelect = value => {
    this.setState({ selected: value })
  }

  render() {
    const { classes, anonymous, title, description } = this.props
    const { selected, error } = this.state
    return (
      <Notification anonymous={anonymous} title={title} description={description}>
        <div className={classes.buttons}>
          <Fab size="small" color={selected === 1 ? 'secondary' : 'default'} className={classes.fab} onClick={() => this.handleSelect(1)}>
            1
          </Fab>
          <Fab size="small" color={selected === 2 ? 'secondary' : 'default'} className={classes.fab} onClick={() => this.handleSelect(2)}>
            2
          </Fab>
          <Fab size="small" color={selected === 3 ? 'secondary' : 'default'} className={classes.fab} onClick={() => this.handleSelect(3)}>
            3
          </Fab>
          <Fab size="small" color={selected === 4 ? 'secondary' : 'default'} className={classes.fab} onClick={() => this.handleSelect(4)}>
            4
          </Fab>
          <Fab size="small" color={selected === 5 ? 'secondary' : 'default'} className={classes.fab} onClick={() => this.handleSelect(5)}>
            5
          </Fab>
        </div>
        {/* {selected !== null && ( */}
        <Button variant="contained" color="secondary" className={classes.confirmButton} onClick={() => this.handleClick(selected)}>
          Confirmar
        </Button>
        {/* )} */}
        {error && <label className={classes.error}>Selecione uma nota e confirme.</label>}
      </Notification>
    )
  }
}
