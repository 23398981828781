import React from 'react'
import { Radio as BaseRadio, FormControlLabel, withStyles, Divider } from '@material-ui/core'

const styles = theme => ({
  divider: {
    marginLeft: 32
  }
})

const Radio = ({ classes, label, value, className, inputref, divider = true, ...props }) => (
  <React.Fragment>
    {divider ? <Divider className={classes.divider} /> : null}
    <FormControlLabel value={value} className={className} control={<BaseRadio {...props} />} label={label} />
  </React.Fragment>
)

export default withStyles(styles)(Radio)
