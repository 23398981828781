import React from 'react'
import { withStyles } from '@material-ui/core'
import cs from 'classnames'

const styles = theme => ({
  main: {
    height: 'calc(100vh - 116px)',
    display: 'flex'
  },
  vertical: {
    flexDirection: 'column'
  }
})

class Span extends React.Component {
  render() {
    const { className, classes, vertical, ...props } = this.props
    return <div className={cs(className, classes.main, vertical && classes.vertical)} {...props} />
  }
}

export default withStyles(styles)(Span)
