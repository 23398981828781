import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Button } from '@material-ui/core'
// import Button from '../../atoms/Button'

const styles = theme => ({
  button: {
    flex: 1,
    textTransform: 'none'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    zIndex: 1
  }
})

const BusyButton = ({ classes, busy, disabled, children, fullWidth, className, ...props }) => {
  return (
    <React.Fragment>
      <Button {...props} className={`${classes.button} ${className}`} disabled={busy === true || disabled === true}>
        {!busy ? children : <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    </React.Fragment>
  )
}

export default withStyles(styles)(BusyButton)
