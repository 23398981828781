import React from 'react'
import ReactDOM from 'react-dom'
import 'resources/css/index.css'
import { WebApp, AppStore } from '@faicon/core'
import * as serviceWorker from 'resources/serviceWorker'
import pt from 'resources/locales/pt-br'
import es from 'resources/locales/es'
import en from 'resources/locales/en-us'
import Configuration from './Configuration'
import { ProductStore } from 'stores'

// const Maintenance = (
//   <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
//   alignItems: 'center',
//   flexDirection: 'column' }}>
//     <img src={require('resources/images/logo.png')} />
//     <h2>Estamos em manutenção, voltaremos em breve</h2>
//   </div>
// )

const Root = (
  <WebApp
    menuFixed
    pt={pt}
    es={es}
    en={en}
    background={[require('resources/images/background.png')]}
    menuBackground={require('resources/images/sidebar-1.jpg')}
    appName="Novoto"
    appUrl="https://www.novoto.com.br"
    privacyUrl="https://www.novoto.com.br/privacy"
    configurations={<Configuration />}
    logos={{
      full: require('resources/images/logo.png'),
      sidebar: require('resources/images/logo_square.png'),
      solid: require('resources/images/logo_white.png')
    }}
    onLogout={() => {
      ProductStore.reset([])
    }}
    primaryColor="#00796C"
    secondaryColor="#007AFF"
    api={`${process.env.REACT_APP_HOST}/api/`}
    // pt={pt}
  />
)

// ReactDOM.render(Maintenance, document.getElementById('root'))
ReactDOM.render(Root, document.getElementById('root'))

const onUpdate = registration => {
  AppStore.updateAvailable(registration.waiting)
}

const onSuccess = registration => {
  // console.log(registration)
  // console.log('registrado')
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register({ onUpdate, onSuccess })
