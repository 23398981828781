// import React from 'react'
import apiClient from "./mobxRest/apiClient";
import adapter from "./Adapter";
export default (function (apiPath) {
  var token = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var tokenKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Authorization';
  var headersObj = {
    'Content-Type': 'application/json'
  };

  if (headers) {
    headersObj = Object.assign(headersObj, headers);
  }

  if (token) {
    headersObj[tokenKey] = token;
  }

  apiClient(adapter(), {
    apiPath: apiPath,
    commonOptions: {
      headers: headersObj
    }
  });
});