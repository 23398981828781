import React from 'react'
import { withStyles, FormLabel, FormControl } from '@material-ui/core'
import { RadioGroup as BaseRadioGroup } from '@material-ui/core'
//import Label from '../../atoms/Label' --------- CONSERTAR O ATOM DEPOIS

const styles = theme => ({
  root: {
    padding: 15,
    //flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  formGroup: {},
  label: {
    fontSize: 16,
    fontWeight: 700
  },
  border: {
    borderTop: '1px solid #F1F1F1'
  }
})

const RadioGroup = ({
  classes,
  label,
  value,
  children,
  border = true,
  ...props
}) => (
  <FormControl className={classes.root}>
    <FormLabel className={classes.label}>{label}</FormLabel>
    <BaseRadioGroup
      className={`${classes.formGroup} ${border && classes.border}`}
      value={value}
      {...props}
    >
      {children}
    </BaseRadioGroup>
  </FormControl>
)

export default withStyles(styles)(RadioGroup)
