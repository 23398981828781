import React from 'react'
import { Input, InputAdornment, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'

const style = theme => ({
  iconButton: {
    padding: 6
  }
})

export default
@withStyles(style)
class PasswordField extends React.Component {
  state = {
    showPassword: false
  }

  render() {
    const { classes, color, ...props } = this.props
    const { showPassword } = this.state
    return (
      <Input
        type={showPassword ? 'text' : 'password'}
        {...props}
        color={color && color}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className={classes.iconButton}
              tabIndex={-1}
              aria-label="Ver senha"
              onPointerDown={() => this.setState({ showPassword: true })}
              onPointerUp={() => this.setState({ showPassword: false })}
              onPointerLeave={() => this.setState({ showPassword: false })}
            >
              <Visibility />
            </IconButton>
          </InputAdornment>
        }
      />
    )
  }
}
