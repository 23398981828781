import React from 'react'
import {
  TextField,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core'
import Button from '../../atoms/Button'
import { UserStore } from '@faicon/core'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const style = {
  appbar: {
    position: 'relative',
    color: 'white'
  },

  textField: {
    margin: '15px 0'
  },

  button: {
    color: '#007AFF',
    border: '1px solid #007AFF', //to-do: cor hardcoded
    margin: 20
  }
}

class ChangePassword extends React.Component {
  state = {
    open: false,
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    oldPasswordError: null,
    newPasswordError: null,
    newPasswordConfirmError: null
  }

  open = () => {
    this.setState({ open: true })
  }

  handleCancel = () => {
    this.setState({ open: false })
  }

  handleConfirm = () => {
    let validation = true
    if (this.state.oldPassword.length === 0) {
      validation = false
      this.setState({
        oldPasswordError: 'Você precisa digitar sua senha atual'
      })
    }

    if (this.state.newPassword.length === 0) {
      validation = false
      this.setState({
        newPasswordError: 'Você não escolheu uma nova senha'
      })
    }

    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      validation = false
      this.setState({
        newPasswordConfirmError: 'Essa senha não está igual a anterior'
      })
    }

    if (validation) {
      this.setState({
        busy: true,
        oldPasswordError: null,
        newPasswordError: null,
        newPasswordConfirmError: null
      })
      UserStore.changePassword(this.state.oldPassword, this.state.newPassword)
        .then(() => this.setState({ open: false, busy: false }))
        .catch(err => {
          if (err) {
            this.setState({
              oldPasswordError: 'A senha atual está incorreta',
              busy: false
            })
          } else {
            this.setState({
              newPasswordError: 'A precisa ter 8 digitos',
              busy: false
            })
          }
        })
      // this.props.onComplete()
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirm,
      oldPasswordError,
      newPasswordError,
      newPasswordConfirmError
    } = this.state
    return (
      <Dialog
        TransitionComponent={Transition}
        open={this.state.open}
        onClose={this.handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar style={style.appbar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCancel}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Alterar Senha
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            name="oldPassword"
            value={oldPassword}
            autoFocus
            label="Digite sua atual"
            fullWidth
            error={!!oldPasswordError}
            helperText={oldPasswordError}
            type="password"
            onChange={this.handleChange}
            style={style.textField}
          />
          <TextField
            name="newPassword"
            value={newPassword}
            label="Digite uma nova senha"
            fullWidth
            error={!!newPasswordError}
            helperText={newPasswordError}
            type="password"
            onChange={this.handleChange}
            style={style.textField}
          />
          <TextField
            name="newPasswordConfirm"
            value={newPasswordConfirm}
            label="Confirme a nova senha"
            fullWidth
            error={!!newPasswordConfirmError}
            helperText={newPasswordConfirmError}
            type="password"
            onChange={this.handleChange}
            style={style.textField}
          />
        </DialogContent>
        <Button onClick={this.handleCancel} style={style.button}>
          Salvar Alteração
        </Button>
      </Dialog>
    )
  }
}

export default ChangePassword
