import React from 'react'
import { withStyles } from '@material-ui/core'
import { observer, inject } from '@faicon/core'

const styles = theme => ({
  root: {
    display: 'flex',
    margin: '0 0 2px 4px',
    flexWrap: 'wrap'
  },
  day: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 5px 7px',
    fontSize: 12
  },
  neutralColor: {
    backgroundColor: theme.palette.grey[600],
    color: 'white'
  },
  failedColor: {
    backgroundColor: 'black',
    color: 'white'
  },
  achievedColor: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  }
})

@withStyles(styles)
@inject('router')
@observer
class CampaignFooter extends React.Component {
  resolveItemColor = (status, classes) => {
    switch (status) {
      case 'failed':
        return classes.failedColor
      case 'achieved':
        return classes.achievedColor
      default:
        return classes.neutralColor
    }
  }

  render() {
    const { classes } = this.props
    // const { errorMessage, /*busy*/ openSuccess, error } = this.state

    return (
      <div className={classes.root}>
        {days.map((item, index) => (
          <div
            key={index}
            className={`
            ${classes.day}
            ${this.resolveItemColor(item.status, classes)}`}
          >
            {item.day}
          </div>
        ))}
      </div>
    )
  }
}

export default withStyles(styles)(CampaignFooter)

const days = [
  { day: 1, status: 'achieved' },
  { day: 2, status: 'failed' },
  { day: 3, status: 'achieved' },
  { day: 4, status: 'achieved' },
  { day: 5, status: 'achieved' },
  { day: 6, status: 'achieved' },
  { day: 7, status: 'achieved' },
  { day: 8, status: 'failed' },
  { day: 9, status: 'failed' },
  { day: 10, status: 'neutral' },
  { day: 11, status: 'neutral' },
  { day: 12, status: 'neutral' },
  { day: 13, status: 'neutral' },
  { day: 14, status: 'neutral' },
  { day: 15, status: 'neutral' },
  { day: 16, status: 'neutral' },
  { day: 17, status: 'neutral' },
  { day: 18, status: 'neutral' },
  { day: 19, status: 'neutral' },
  { day: 20, status: 'neutral' },
  { day: 21, status: 'neutral' },
  { day: 22, status: 'neutral' },
  { day: 23, status: 'neutral' },
  { day: 24, status: 'neutral' },
  { day: 25, status: 'neutral' },
  { day: 26, status: 'neutral' },
  { day: 27, status: 'neutral' },
  { day: 28, status: 'neutral' },
  { day: 29, status: 'neutral' },
  { day: 30, status: 'neutral' }
]
