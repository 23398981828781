import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const styles = theme => ({
  submitButton: {
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    '&:hover': {
      backgroundColor: '#1A62FF'
    }
  },

  fab: {
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  }
})

const ButtonAtom = ({ classes, ...props }) => {
  return (
    <Button
      classes={{
        fab: classes.fab,
        root: classes.submitButton
      }}
      {...props}
    >
    {props.children}
    </Button>
  )
}

ButtonAtom.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ButtonAtom)
