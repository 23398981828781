import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from './../../molecules/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'

class AlertDialog extends React.Component {
  state = {
    open: false
  }

  state = {
    open: false
  }

  open = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.onClose && this.props.onClose()
  }

  handleConfirm = () => {
    this.handleClose()
    this.props.onConfirm && this.props.onConfirm()
  }

  render() {
    const { message, onConfirm, onClose, okButtonMessage, ...props } = this.props
    return (
      <Dialog
        fullScreen={false}
        {...props}
        content={
          <DialogContentText>
            {message}
            {/* {<span dangerouslySetInnerHTML={{ __html: message }} />} */}
          </DialogContentText>
        }
        actions={
          <Button onClick={this.handleConfirm} color="secondary" autoFocus>
            {okButtonMessage || 'Ok'}
          </Button>
        }
      />
    )
  }
}

export default AlertDialog
