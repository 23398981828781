import React from 'react'
import { withStyles, Button, LinearProgress } from '@material-ui/core'
import Attachment from '@material-ui/icons/Attachment'
import Uploader from './../Uploader'
import Add from '@material-ui/icons/Add'
import Close from '@material-ui/icons/Close'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import Dropzone from 'react-dropzone'
import cs from 'classnames'
import { ResourceLoader } from '@faicon/core'
import { ConfirmDialog } from 'draft'

const style = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    textAlign: 'center',
    justifyContent: 'center',
    height: '100%',
    boxSizing: 'border-box'
  },
  mainPad: {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[400],
    padding: 30
  },
  dragActive: {
    backgroundColor: blue[200],
    borderColor: theme.palette.secondary.dark
  },
  progress: {
    width: '100%'
  },
  errorLabel: {
    color: theme.palette.error.main
  },
  error: {
    backgroundColor: red[50],
    borderColor: theme.palette.error.main
  },
  success: {
    backgroundColor: green[50],
    borderColor: green[600]
  },
  button: {
    marginBottom: 10
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  img: {
    maxHeight: 'calc(100% - 56px)',
    maxWidth: '100%',
    objectFit: 'scale-down'
  },
  marginTop: {
    marginTop: 10
  }
})

export default
@withStyles(style)
class Dropfile extends React.Component {
  state = {
    filename: null,
    uploadComplete: false,
    uploading: false,
    progress: 60,
    uploadError: null,
    serverFile: null,
    currentPicture: null,
    noPadding: false,
    confirmDialog: false,
    capture: false
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0 && rejectedFiles.length === 0) {
      this.handleSelected(acceptedFiles[0])
    } else {
      if (!this.props.multiple && rejectedFiles.length > 1) {
        this.setState({ uploadError: 'Arraste apenas um arquivo' })
      } else {
        this.setState({ uploadError: 'Arquivo em formato inválido' })
      }
    }
  }

  handleSelected = file => {
    this.setState({ filename: file.filename })
    this.uploader.start(file)
  }

  handleStart = file => {
    this.setState({
      currentPicture: URL.createObjectURL(file)
    })
  }

  handleUploadClick = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({ confirmDialog: true })
    } else {
      this.dropzone.open()
    }
  }

  handleConfirmUpload = capture => {
    this.setState({ capture, confirmDialog: false }, this.dropzone.open)
  }

  handleReset = e => {
    this.setState({ noPadding: false, uploadError: null, uploading: false, filename: null, serverFile: null, currentPicture: null })
    this.props.onUploadFinish && this.props.onUploadFinish(null)
  }

  handleError = e => {
    this.setState({ uploading: false, uploadError: true })
  }

  handleProgress = value => {
    this.setState({ progress: value, uploading: true })
  }

  onImageLoad = result => {
    this.setState({ noPadding: true })
  }

  handleFinish = result => {
    this.setState({
      uploadComplete: true,
      uploading: false,
      serverFile: result.filename
    })
    this.props.onUploadFinish && this.props.onUploadFinish(result.filename)
  }

  render() {
    const { classes, className, label, showButton, color, value, imageOnly, sheetOnly, accept = null, multiple = false, error, ...props } = this.props
    const { uploadError, uploading, progress, filename, serverFile, currentPicture, noPadding, confirmDialog, capture } = this.state

    let currentFile = value || serverFile

    const splited = currentFile && String(currentFile).split('/')
    const currentFileName = currentFile ? splited[splited.length - 1].substring(37) : filename

    let resolvedAccept = accept
    if (sheetOnly) {
      resolvedAccept = '.xlsx,.xls'
    } else if (imageOnly) {
      resolvedAccept = 'image/*'
    }

    let displayPicture = currentPicture
    if (!displayPicture && value) {
      displayPicture = ResourceLoader.load(value)
    }

    return (
      <Dropzone
        ref={me => (this.dropzone = me)}
        onDrop={this.handleDrop}
        multiple={multiple}
        accept={resolvedAccept}
        onClick={evt => evt.preventDefault()}
        // onClick={evt => (multiple || !!uploadError || !!currentFile ? evt.preventDefault() : null)}
        {...props}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={cs(
                className,
                classes.main,
                !noPadding && classes.mainPad,
                isDragActive && classes.dragActive,
                !multiple && classes.cursorPointer,
                (uploadError || error) && classes.error,
                currentFile && !noPadding && classes.success
              )}
            >
              {imageOnly && (
                <ConfirmDialog
                  open={confirmDialog}
                  fullScreen={false}
                  onConfirm={() => this.handleConfirmUpload(true)}
                  onDisagree={() => this.handleConfirmUpload(false)}
                  onBackdropClick={() => this.setState({ confirmDialog: false })}
                  onClose={() => this.setState({ confirmDialog: false })}
                  message="Deseja tirar uma nova foto ou selecionar uma já existente?"
                  agreeButtonMessage="Nova foto"
                  disagreeButtonMessage="Existente"
                />
              )}
              <Uploader
                ref={me => (this.uploader = me)}
                {...getInputProps({ refKey: 'inputRef' })}
                accept={resolvedAccept}
                folder="temp/"
                autoUpload={false}
                onStart={this.handleStart}
                onProgress={this.handleProgress}
                onError={this.handleError}
                onFinish={this.handleFinish}
                capture={capture}
              />
              {currentFile ? (
                imageOnly ? (
                  <>
                    <img src={displayPicture} onLoad={this.onImageLoad} alt="Imagem" className={classes.img} />
                    <Button onClick={this.handleReset} className={cs(classes.button, classes.marginTop)}>
                      <Close />
                    </Button>
                  </>
                ) : (
                  <>
                    <Attachment />
                    <p>{currentFileName}</p>
                    <Button onClick={this.handleReset} className={classes.button}>
                      <Close />
                    </Button>
                  </>
                )
              ) : uploading ? (
                <>
                  <p>Enviando: {currentFileName}</p>
                  <LinearProgress color="primary" variant="determinate" value={progress} className={classes.progress} />
                  <p>{progress}%</p>
                </>
              ) : uploadError ? (
                <>
                  <p>{uploadError}</p>
                  <Button variant="contained" onClick={this.handleReset} className={classes.button}>
                    Fazer upload de outro arquivo
                  </Button>
                </>
              ) : (
                <>
                  <div onClick={this.handleUploadClick}>
                    {!multiple && <Add />}
                    <p>{label || (multiple ? 'Solte aqui ou selecione os arquivos.' : 'Solte aqui ou clique para selecionar um arquivo.')}</p>
                  </div>
                  {multiple && (
                    <Button variant="contained" onClick={this.handleUploadClick} className={classes.button}>
                      Procurar arquivos
                    </Button>
                  )}
                </>
              )}
              {error && <p className={classes.errorLabel}>{error}</p>}
            </div>
          )
        }}
      </Dropzone>
    )
  }
}
