import React from 'react'
import { withStyles, FormControl, InputLabel, Select as BaseSelect } from '@material-ui/core'

const styles = theme => ({})

const Select = ({ classes, children, onChange, label, required, ...props }) => (
  <FormControl required={required} fullWidth>
    <InputLabel>{label}</InputLabel>
    <BaseSelect {...props} required={required} pattern="[A-Za-z0-9]{1,20}" onChange={e => onChange(e.target.value, e.target.name)}>
      {children}
    </BaseSelect>
  </FormControl>
)

export default withStyles(styles)(Select)
