import React from 'react'
import { UserStore, observer, ConfigSection, ConfigItem, I18n } from '@faicon/core'
import { withStyles, Button } from '@material-ui/core'
import { OccupationStore } from 'stores'
import { ConfirmDialog } from 'draft'
import { ModalBusy } from 'components'

const styles = theme => ({
  footer: {
    marginTop: 16,
    marginBottom: 26,
    boxSizing: 'border-box',
    padding: 2,
    maxWidth: 960,
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  error: {
    color: theme.palette.error.main
  }
})

let localeKeys = ['pt-br', 'en-us', 'es']

export default
@withStyles(styles)
@observer
class MyAccount extends React.Component {
  state = { confirmDialog: 0, destroying: false }

  componentDidMount() {
    OccupationStore.scope()
      .where({ locale: UserStore.logged.locale })
      .all()
  }

  removeAccount = () => {
    this.setState({ destroying: true })
    this.handleChange('enabled')(false).then(() => UserStore.logout())
  }

  handleChange = name => value => {
    UserStore.workingModel.set({ [name]: value })
    return UserStore.workingModel.save()
  }

  render() {
    const { classes } = this.props
    const { confirmDialog, destroying } = this.state

    const locales = I18n.t('idiomas')
      .split(',')
      .map((locale, index) => ({ id: localeKeys[index], label: locale }))

    const genders = I18n.t('generos')
      .split(',')
      .map(gender => ({ id: gender, label: gender }))

    const civilstatuses = I18n.t('estados_civis')
      .split(',')
      .map(civilstatus => ({ id: civilstatus, label: civilstatus }))

    if (destroying) {
      return <ModalBusy busy={true} />
    }

    return (
      <>
        <ConfigSection title={I18n.t('configuracoes')}>
          <ConfigItem
            label={I18n.t('idioma')}
            helperText={I18n.t('prompt_idioma')}
            value={UserStore.workingModel.g('locale')}
            onChange={this.handleChange('locale')}
            afterUpdate={UserStore.current}
            lastRow
            list={locales}
          />
        </ConfigSection>

        <ConfigSection title={I18n.t('dados_pessoais')}>
          <ConfigItem label={I18n.t('nome_usuario')} value={UserStore.workingModel.g('name')} onChange={this.handleChange('name')} />
          <ConfigItem label={I18n.t('nascimento')} value={UserStore.workingModel.g('birthdate')} type="date" onChange={this.handleChange('birthdate')} />
          <ConfigItem
            label={I18n.t('profissao')}
            value={UserStore.workingModel.g('occupationId')}
            onChange={this.handleChange('occupationId')}
            list={OccupationStore.toJS()}
            labelField="name"
          />
          <ConfigItem label={I18n.t('genero')} value={UserStore.workingModel.g('gender')} onChange={this.handleChange('gender')} list={genders} />
          <ConfigItem
            label={I18n.t('estado_civil')}
            value={UserStore.workingModel.g('civilstatus')}
            onChange={this.handleChange('civilstatus')}
            list={civilstatuses}
          />
          <ConfigItem
            label={I18n.t('telefone')}
            value={UserStore.workingModel.g('phone')}
            type="tel"
            onChange={this.handleChange('phone')}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          />
          <ConfigItem
            label={I18n.t('celular')}
            value={UserStore.workingModel.g('mobile')}
            type="tel"
            lastRow
            onChange={this.handleChange('mobile')}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          />
        </ConfigSection>

        <ConfigSection title={I18n.t('notificacoes')}>
          <ConfigItem
            label={I18n.t('aviso_ultimo_dia')}
            helperText={I18n.t('prompt_aviso_ultimo_dia')}
            value={UserStore.workingModel.g('notificationsEnabled')}
            onChange={this.handleChange('notificationsEnabled')}
            boolean
          />
          <ConfigItem
            label={I18n.t('aviso_30')}
            helperText={I18n.t('prompt_aviso_30')}
            value={UserStore.workingModel.g('notificationsThirtyDays')}
            boolean
            onChange={this.handleChange('notificationsThirtyDays')}
          />
          <ConfigItem
            label={I18n.t('aviso_email')}
            helperText={I18n.t('prompt_aviso_email')}
            value={UserStore.workingModel.g('allowSendEmail')}
            lastRow
            boolean
            onChange={this.handleChange('allowSendEmail')}
          />
        </ConfigSection>
        <div className={classes.footer}>
          <Button className={classes.error} onClick={() => this.setState({ confirmDialog: 1 })}>
            {I18n.t('excluir_conta')}
          </Button>
        </div>
        <ConfirmDialog
          open={confirmDialog === 1}
          fullScreen={false}
          onConfirm={() => this.setState({ confirmDialog: 2 })}
          onClose={() => this.setState({ confirmDialog: 0 })}
          message={I18n.t('excluir_conta_confirma')}
          agreeButtonMessage={I18n.t('apagar_conta')}
          disagreeButtonMessage={I18n.t('cancelar')}
        />
        <ConfirmDialog
          open={confirmDialog === 2}
          fullScreen={false}
          onConfirm={this.removeAccount}
          onClose={() => this.setState({ confirmDialog: 0 })}
          message={I18n.t('excluir_conta_confirma2')}
          agreeButtonMessage={I18n.t('apagar_conta')}
          disagreeButtonMessage={I18n.t('cancelar')}
        />
      </>
    )
  }
}
