import React from 'react'
import cx from 'classnames'

// material-ui components
import { withStyles, Input, InputLabel, FormHelperText, FormControl } from '@material-ui/core'

// material-ui-icons
import Clear from '@material-ui/icons/Clear'
import Check from '@material-ui/icons/Check'

const styles = theme => ({
  disabled: {
    '&:before': {
      background: 'transparent !important',
      backgroundSize: '3px 1px !important',
      backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.23) 33%, transparent 0%) !important',
      backgroundRepeat: 'repeat-x !important',
      backgroundPosition: 'left top !important'
    }
  },
  underline: {
    '&:before': {
      backgroundColor: '#D2D2D2 !important',
      height: '1px !important'
    }
  },
  labelRoot: {
    color: '#AAAAAA',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    transition: '0.3s ease all'
  },
  labelRootError: {
    color: theme.palette.error.main
  },
  labelRootSuccess: {
    color: theme.palette.secondary.light
  },
  feedback: {
    position: 'absolute',
    top: '23px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none'
  },
  feedbackNoLabel: {
    // top: "8px"
  },
  input: {
    padding: '2px 0 0',
    fontWeight: '400',
    height: '36px',
    fontSize: '14px',
    lineHeight: '1.428571429',
    color: '#555',
    '&[rows]': {
      height: 'auto'
    }
  },
  inputNoLabel: {
    paddingTop: '18px'
  },
  inputRTL: {
    textAlign: 'right'
  },
  inputWithAdornment: {
    paddingTop: '21px'
  },
  formControl: {
    paddingBottom: '10px',
    position: 'relative'
  },
  labelWithAdornment: {
    top: '3px'
  },
  feedbackAdorment: {
    right: '22px'
  }
})

export default
@withStyles(styles)
class CustomInput extends React.Component {
  render() {
    const { classes, formControlProps, labelText, id, labelProps, inputProps, error, success, helpText, rtlActive } = this.props

    var labelClasses = cx({
      [' ' + classes.labelRootError]: error,
      [' ' + classes.labelRootSuccess]: success && !error
    })

    var formControlClasses = classes.formControl
    if (formControlProps !== undefined) {
      formControlClasses += ' ' + formControlProps.className
    }
    var underlineClasses = classes.underline
    if (inputProps !== undefined) {
      formControlClasses =
        formControlClasses +
        ' ' +
        cx({
          [classes.inputWithAdornment]: (inputProps.startAdornment !== undefined || inputProps.endAdornment !== undefined) && labelText === undefined
        })
      underlineClasses = cx({
        [classes.underline]: inputProps.disabled !== true
      })
    }
    if (inputProps !== undefined) {
      labelClasses =
        labelClasses +
        ' ' +
        cx({
          [classes.labelWithAdornment]: inputProps.endAdornment !== undefined
        })
    }
    const successClasses =
      classes.feedback +
      ' ' +
      classes.labelRootSuccess +
      ' ' +
      cx({
        [classes.feedbackNoLabel]: labelText === undefined,
        [classes.feedbackAdorment]: inputProps !== undefined && inputProps.endAdornment !== undefined
      })
    const errorClasses =
      classes.feedback +
      ' ' +
      classes.labelRootError +
      ' ' +
      cx({
        [classes.feedbackNoLabel]: labelText === undefined,
        [classes.feedbackAdorment]: inputProps !== undefined && inputProps.endAdornment !== undefined
      })
    const input =
      classes.input +
      ' ' +
      cx({
        [classes.inputRTL]: rtlActive,
        [classes.inputNoLabel]: labelText === undefined
      })
    return (
      <FormControl {...formControlProps} className={formControlClasses} aria-describedby={id + '-text'}>
        {labelText !== undefined ? (
          <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          classes={{
            input: input,
            disabled: classes.disabled,
            underline: underlineClasses
          }}
          id={id}
          {...inputProps}
        />
        {error ? <Clear className={errorClasses} /> : success ? <Check className={successClasses} /> : null}
        {helpText !== undefined ? <FormHelperText id={id + '-text'}>{helpText}</FormHelperText> : null}
      </FormControl>
    )
  }
}
