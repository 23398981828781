import React from 'react'
import {
  withStyles,
  InputLabel,
  NativeSelect,
  Input,
  FormControl,
  InputAdornment,
  IconButton
} from '@material-ui/core'

const styles = theme => ({
  formControl: {
    minWidth: '100%'
  }
})

const InputLabelAdmin = ({ classes, name, valueOption, valueLabel, id }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="uncontrolled-native">Nome do Supervisor</InputLabel>
      <NativeSelect
        input={
          <Input
            name="name"
            id="uncontrolled-native"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.handleClick}
                  alt="Apagar campanha"
                >
                  <i className="material-icons">close</i>
                </IconButton>
              </InputAdornment>
            }
          />
        }
      >
        <option value="Selecione um tipo" />
        <option value={10}>Ten</option>
        <option value={20}>Twenty</option>
        <option value={30}>Thirty</option>
      </NativeSelect>
    </FormControl>
  )
}

export default withStyles(styles)(InputLabelAdmin)
