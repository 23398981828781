import React from 'react'
import { withStyles, Typography, Button } from '@material-ui/core'
import { observer, Form } from '@faicon/core'
import { Paper, Grid } from '@material-ui/core'
// import 'react-viewer/dist/index.css'
import { ProductStore } from 'stores'
import { AlertDialog, TextField } from 'draft'

// import Search from '@material-ui/icons/Search'

const styles = theme => ({
  form: {
    flex: 1
  },
  paper: {
    padding: 20
  },
  p: {
    marginBottom: 30
  },
  buttonGreen: {
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 164, 0)',
    fontWeight: 'bold',
    marginRight: 10,
    '&:hover': {
      boxShadow: '0 2px 4px #888888',
      backgroundColor: 'rgb(0, 164, 0)'
    },
    '&:focus': {
      boxShadow: '0 2px 4px #888888',
      backgroundColor: 'rgb(0, 164, 0)'
    }
  }
})

export default
@withStyles(styles)
@observer
class Product extends React.Component {
  state = {}

  componentDidMount = () => {
    if (this.props.match.params.id) {
      if (ProductStore.isEmpty) {
        ProductStore.where({ id: this.props.match.params.id })
          .scope()
          .all()
          .then(() => (ProductStore.workingModel = ProductStore.get(this.props.match.params.id)))
      } else {
        ProductStore.workingModel = ProductStore.get(this.props.match.params.id)
      }
    } else {
      ProductStore.init({})
    }
  }

  componentWillUnmount() {
    if (ProductStore.length === 1) {
      ProductStore.reset([])
    }
  }

  handleFormSubmit = event => {
    event.preventDefault()
    ProductStore.workingModel.save(ProductStore.workingModel.toJS()).then(result => {
      let message = `Id da loja: ${result.id}!`
      this.setState({ message: message, detail: true })
    })
  }

  handleChange = name => value => {
    ProductStore.workingModel.s(name, value)
  }

  render() {
    const { classes } = this.props
    const { detail, message } = this.state

    if (!ProductStore.workingModel) {
      return <span>Carregando...</span>
    }

    return (
      <Form onSubmit={this.handleFormSubmit} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" component="h3">
                    Gerenciar loja
                  </Typography>
                  <Typography component="p" className={classes.p}>
                    Defina as informações de sua loja.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Razão Social"
                    placeholder="Digite a razão social"
                    onChange={this.handleChange('name')}
                    value={ProductStore.workingModel.g('name')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="CNPJ"
                    placeholder="Digite o CNPJ"
                    onChange={this.handleChange('cnpj')}
                    value={ProductStore.workingModel.g('cnpj')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Rede"
                    onChange={this.handleChange('merchant.alternativeName')}
                    value={ProductStore.workingModel.g('merchant.alternativeName')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="nome"
                    label="Nome Especial"
                    placeholder="Defina um nome próprio para a loja"
                    onChange={this.handleChange('customStore.name')}
                    value={ProductStore.workingModel.g('customStore.name')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" component="h3">
                    Dados de contato
                  </Typography>
                  <Typography component="p" className={classes.p}>
                    Defina as informações de contato da loja.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    name="DDD"
                    label="DDD"
                    placeholder="Digite o DDD"
                    onChange={this.handleChange('contactData.ddd')}
                    value={ProductStore.workingModel.g('contactData.ddd')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextField
                    name="telefone"
                    label="Telefone"
                    placeholder="Digite o telefone"
                    onChange={this.handleChange('contactData.phone')}
                    value={ProductStore.workingModel.g('contactData.phone')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    name="DDD"
                    label="DDD"
                    placeholder="Digite o DDD"
                    onChange={this.handleChange('contactData.dddMobile')}
                    value={ProductStore.workingModel.g('contactData.dddMobile')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextField
                    name="celular"
                    label="Celular"
                    placeholder="Digite o celular"
                    onChange={this.handleChange('contactData.mobile')}
                    value={ProductStore.workingModel.g('contactData.mobile')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} align="left" className={classes.buttons}>
            <Button className={classes.buttonGreen} type="submit">
              Salvar
            </Button>
            <Button className={classes.button}>Cancelar</Button>
          </Grid>
        </Grid>

        <AlertDialog open={detail} onClose={() => this.setState({ detail: false })} title="Ação realizada com sucesso." message={message} />
      </Form>
    )
  }
}
