import React from 'react'
import {
  withStyles,
  Switch as BaseSwitch,
  FormHelperText,
  FormControlLabel,
  FormControl
} from '@material-ui/core'

const styles = theme => ({})

export default
@withStyles(styles)
class Switch extends React.Component {
  state = {}

  render() {
    const {
      classes,
      onChange,
      label,
      helper,
      required,
      fullWidth = true,
      ...props
    } = this.props

    return (
      <FormControl required={required} fullWidth={fullWidth}>
        <FormControlLabel
          control={
            <BaseSwitch onChange={e => onChange(e.target.checked)} {...props} />
          }
          label={label}
        />
        {helper && <FormHelperText>{helper}</FormHelperText>}
      </FormControl>
    )
  }
}
