import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroudColor: 'white',
    padding: '5px 10px',
    backgroundColor: 'white',
    borderBottom: '1px solid #C7C7CD'
  },

  header: {
    padding: '9.5px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    height: 73
  },
  serviceText: {
    fontWeight: 300,
    fontSize: 14,
    height: 40
  },
  serviceGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  mainColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'right',
    paddingRight: 10
  },
  infoColumn: {
    display: 'flex',
    paddingTop: 0,
    flexDirection: 'column',
    flexGrow: 2,
    paddingLeft: 10
  },

  infoText: {
    fontWeight: 400,
    fontSize: 14,
    height: 40
  },

  title: {
    flex: 4,
    fontSize: 16,
    fontWeight: 700,
    padding: '0 10px 0 0',
    overflow: 'hidden'
  },

  prices: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  radio: {
    alignSelf: 'flex-end',
    height: 73,
    paddingTop: 7
  },

  price: {
    fontSize: 20,
    fontWeight: 700
  },
  previousPrice: {
    fontSize: 20,
    fontWeight: 700,
    color: '#C7C7CD',
    textDecoration: 'line-through'
  }
})

class MigrationItem extends React.Component {
  render() {
    const {
      classes,
      title,
      previousPrice,
      currentPrice,
      internetDetail,
      minutosDetail,
      wifiDetail,
      extrasDetail,
      ...props
    } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.serviceGroup}>
          <div className={classes.mainColumn}>
            <Radio
              className={classes.radio}
              name="radio-button-demo"
              {...props}
            />
            <label className={classes.serviceText}>Internet</label>
            <label className={classes.serviceText}>Minutos</label>
            <label className={classes.serviceText}>Wifi</label>
            <label className={classes.serviceText}>Extras</label>
          </div>
          <div className={classes.infoColumn}>
            <div className={classes.header}>
              <label className={classes.title}>{title}</label>
              <div>
                {currentPrice === previousPrice ? (
                  <label className={classes.price}>
                    R$
                    {currentPrice}
                  </label>
                ) : (
                  <div className={classes.prices}>
                    <label className={classes.previousPrice}>
                      R$
                      {previousPrice}
                    </label>
                    <label className={classes.price}>
                      R$
                      {currentPrice}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <label className={classes.infoText}>{internetDetail}</label>
            <label className={classes.infoText}>{minutosDetail}</label>
            <label className={classes.infoText}>{wifiDetail}</label>
            <label className={classes.infoText}>{extrasDetail}</label>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(MigrationItem)
