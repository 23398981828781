import React from 'react'
import { withStyles } from '@material-ui/core'
import { observer } from '@faicon/core'

const styles = theme => ({})

export default
@withStyles(styles)
@observer
class ProductForm extends React.Component {
  render() {
    // const { classes } = this.props
    return <span>Edit Produto...</span>
  }
}
