import React from 'react'
import Highcharts from 'highcharts'
import noData from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { Paper, withStyles } from '@material-ui/core'
import PeriodNavigator from '../../molecules/PeriodNavigator'
// import ArrowForward from '@material-ui/icons/ArrowForward'
// import ArrowBack from '@material-ui/icons/ArrowBack'
require('highcharts/modules/exporting')(Highcharts)
noData(Highcharts)

const styles = theme => ({
  paper: {
    padding: 10,
    flex: 1,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column'
  },
  toolbar: {
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    minHeight: 50,
    marginRight: 50
  },
  chartContainer: {
    marginTop: -50
  }
})

export default
@withStyles(styles)
class PieChart extends React.Component {
  getChartData = () => ({
    credits: {
      enabled: false
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
        fontFamily: 'Roboto'
      }
    },
    legend: {
      itemStyle: {
        fontWeight: 300
      }
    },
    title: {
      text: this.props.title,
      align: 'right',
      x: -30,
      y: 18
    },
    subtitle: {
      text: this.props.description,
      align: 'right'
    },
    noData: {
      style: {
        fontWeight: 300,
        fontSize: 18
      }
    },
    lang: {
      noData: 'Sem dados para o período selecionado',
      contextButtonTitle: 'Download Gráfico',
      printChart: 'Imprimir',
      downloadCSV: 'Download CSV',
      downloadJPEG: 'Download JPEG',
      downloadPDF: 'Download PDF',
      downloadPNG: 'Download PNG',
      downloadSVG: 'Download SVG',
      downloadXLS: 'Download XLS'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br><b>{point.y}</b>'
    },
    plotOptions: {
      pie: {
        // allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: this.props.series
  })

  render() {
    if (this.chart) {
      if (this.props.loading) {
        this.chart.chart.showLoading('Carregando...')
      } else {
        this.chart.chart.hideLoading()
      }
    }

    const { classes, currentSelection, currentLabel, onBack, onNext } = this.props
    return (
      <Paper className={classes.paper}>
        <div className={classes.toolbar}>
          <PeriodNavigator currentSelection={currentSelection} currentLabel={currentLabel} onBack={onBack} onNext={onNext} />
        </div>
        <div className={classes.chartContainer}>
          <HighchartsReact ref={me => (this.chart = me)} highcharts={Highcharts} options={this.getChartData()} />
        </div>
      </Paper>
    )
  }
}
