import React from 'react'
import { withStyles, Divider } from '@material-ui/core'

const styles = theme => ({
  main: {
    width: 1,
    height: 'auto',
    background:
      'linear-gradient(white 10%, #E1E1E8,  #CBCBD1, #E1E1E8,  white 90%)'
  }
})

class VDivider extends React.Component {
  render() {
    const { classes, margin } = this.props
    return <Divider className={classes.main} style={{ margin: margin }} />
  }
}

export default withStyles(styles)(VDivider)
