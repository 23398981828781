import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import { Redirect } from 'react-router-dom'

const styles = theme => ({})

class DropDownMenu extends React.Component {
  state = {
    anchorEl: null
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClick = originalClick => {
    this.handleClose()
    originalClick()
  }

  render() {
    if (this.state.exit) {
      return (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      )
    }
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    const { children } = this.props

    return (
      <React.Fragment>
        <IconButton onClick={this.handleMenu} aria-label="Menu" color="inherit">
          <MoreVert />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={this.handleClose}
        >
          {React.Children.map(children, (item, index) =>
            React.cloneElement(item, {
              key: index,
              onClick: () => this.handleClick(item.props.onClick)
            })
          )}
        </Menu>
      </React.Fragment>
    )
  }
}

DropDownMenu.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DropDownMenu)
