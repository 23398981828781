import React from 'react'
import { withStyles, TextField, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import cs from 'classnames'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3
    }
  },
  bootstrapInput: {
    borderRadius: 19,
    height: 13,
    padding: '10px 12px',
    flex: 1,
    color: theme.palette.secondary,
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: '#F1F1F1',
    fontFamily: 'Roboto',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '&:disabled': {
      color: '#666666'
    }
  },
  adornment: {
    paddingLeft: 42
  },
  fixAdornment: {
    paddingRight: 56,
    marginRight: -56
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
})

class Input extends React.Component {
  state = {
    showPassword: false,
    message: null
  }

  render() {
    const { classes, placeholder, id, type, helperText, InputProps, errorText, adornment, ...props } = this.props

    if (type === 'password') {
      return (
        <TextField
          {...props}
          error={!!errorText}
          helperText={errorText || helperText}
          placeholder={placeholder}
          type={this.state.showPassword ? 'text' : 'password'}
          id={id}
          fullWidth
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.bootstrapRoot,
              input: cs(classes.bootstrapInput, classes.fixAdornment)
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="Ver senha"
                  onPointerDown={() => this.setState({ showPassword: true })}
                  onPointerUp={() => this.setState({ showPassword: false })}
                  onPointerLeave={() => this.setState({ showPassword: false })}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            shrink: true,
            className: classes.bootstrapFormLabel
          }}
        />
      )
    } else {
      return (
        <div className={classes.root}>
          <TextField
            error={!!errorText}
            placeholder={placeholder}
            type={type ? type : 'text'}
            id={id}
            fullWidth
            {...props}
            helperText={errorText || helperText}
            InputProps={Object.assign(
              {
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: cs(classes.bootstrapInput, adornment && classes.adornment)
                }
              },
              InputProps
            )}
            InputLabelProps={{
              shrink: true,
              className: classes.bootstrapFormLabel
            }}
          />
          {this.props.children}
        </div>
      )
    }
  }
}

export default withStyles(styles)(Input)
