import React from 'react'
import ReactS3Uploader from 'react-s3-uploader'
import S3Upload from 'react-s3-uploader/s3upload.js'

export default class Uploader extends React.Component {
  state = {
    uploadComplete: false,
    uploading: false,
    progress: 0
  }

  // APENAS PARA APRENDER A USAR
  // handleError = e => {
  //   this.setState({ uploading: false, uploadError: true })
  // }

  // handleProgress = value => {
  //   this.setState({ progress: value, uploading: true })
  // }

  handlePreprocess = (file, next) => {
    this.props.onStart && this.props.onStart(file)
    next(file)
  }

  start = file => {
    this.myUploader = new S3Upload({
      files: [file],
      signingUrl: this.uploader.props.signingUrl,
      getSignedUrl: this.uploader.props.getSignedUrl,
      preprocess: this.uploader.props.preprocess,
      onSignedUrl: this.uploader.props.onSignedUrl,
      onProgress: this.uploader.props.onProgress,
      onFinishS3Put: this.uploader.props.onFinish,
      onError: this.uploader.props.onError,
      signingUrlMethod: this.uploader.props.signingUrlMethod,
      signingUrlHeaders: this.uploader.props.signingUrlHeaders,
      signingUrlQueryParams: this.uploader.props.signingUrlQueryParams,
      signingUrlWithCredentials: this.uploader.props.signingUrlWithCredentials,
      uploadRequestHeaders: this.uploader.props.uploadRequestHeaders,
      contentDisposition: this.uploader.props.contentDisposition,
      server: this.uploader.props.server,
      scrubFilename: this.uploader.props.scrubFilename,
      s3path: this.uploader.props.s3path
    })
  }

  // handleFinish = result => {
  //   this.setState({
  //     uploadComplete: true,
  //     uploading: false,
  //     currentPicture: result.filename
  //   })
  //   this.props.onUploadFinish && this.props.onUploadFinish(result)
  // }

  render() {
    const { autoUpload = true, folder = '', filename, onStart, prefix = '', ...props } = this.props

    return (
      <ReactS3Uploader
        {...props}
        ref={me => (this.uploader = me)}
        signingUrl="/s3/sign"
        preprocess={this.handlePreprocess}
        server={process.env.REACT_APP_HOST}
        s3path={`${process.env.REACT_APP_UPLOAD_FOLDER}/${folder}`}
        scrubFilename={fn => filename || prefix + fn.substring(0, 20)}
        autoUpload={autoUpload}
      />
    )
  }
}
