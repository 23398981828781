import React from 'react'
import { withStyles } from '@material-ui/core'
import { inject, I18n, DateUtil } from '@faicon/core'
import { Paper, Divider, IconButton, MenuItem, Chip, Typography, GridListTile, Menu } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Favorite from '@material-ui/icons/Favorite'
import Done from '@material-ui/icons/Done'
import History from '@material-ui/icons/History'
import Add from '@material-ui/icons/Add'
import cs from 'classnames'

const styles = theme => ({
  root: {
    minHeight: 100,
    flex: 1,
    padding: 10,
    margin: 5,
    justifyContent: 'center',
    paddingBottom: 5
  },
  header: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.grey[100],
    margin: -10,
    display: 'flex',
    alignItems: 'center',
    height: 59
  },
  subheader: {
    padding: 10,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  chipRoot: {
    marginRight: 3,
    height: 'auto'
  },
  buttonMenu: {
    width: 40,
    height: 40,
    padding: 0,
    marginRight: 5
  },
  middle: {
    marginTop: 10,
    display: 'flex',
    paddingTop: 4,
    paddingBottom: 10
  },
  warrantItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  vr: {
    margin: 0,
    marginLeft: -1,
    height: 'auto',
    width: 1,
    border: 'none',
    backgroundColor: theme.palette.grey[300]
  },
  footer: {
    paddingTop: 5,
    display: 'flex',
    justifyContent: 'center'
  },
  chip: {
    display: 'block',
    maxWidth: 70,
    margin: 2,
    paddingRight: 4,
    paddingLeft: 4,
    paddingTop: 1,
    fontSize: 11,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  lastChip: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  circle1: {
    borderRadius: '100%',
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#78c541',
    color: theme.palette.common.white
  },
  circle2: {
    borderRadius: '100%',
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f2bd0e',
    color: theme.palette.common.white
  },
  circle3: {
    borderRadius: '100%',
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#dc4a4c',
    color: theme.palette.common.white
  },
  circle4: {
    borderRadius: '100%',
    height: 38,
    width: 38,
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#328eb6',
    color: theme.palette.common.white
  },
  expirade: { width: 18, height: 18, marginRight: 5, color: '#e03533' },
  done: { width: 18, height: 18, marginRight: 5, color: '#69e05a' },
  donated: { width: 18, height: 18, marginRight: 5, color: '#FF0000' }
})

export default
@withStyles(styles)
@inject('router')
class ProductRenderer extends React.Component {
  state = { anchorEl: null }

  handleClose = callback => {
    this.setState({ anchorEl: null }, callback)
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleEdit = event => {
    this.handleClose()
    this.props.onEdit(this.props.product.id)
  }

  hasWarrant = () => {
    let endDate = DateUtil.addMonths(this.props.product.boughtAt, this.props.product.warrantInDays + this.props.product.extendedWarrantInDays)
    this.daysDiffDate = DateUtil.getDifferenceInDaysWithoutHours(Date.now(), endDate)
    return this.daysDiffDate < 0
  }

  labelDateText = () => {
    let endDate = DateUtil.addMonths(this.props.product.boughtAt, this.props.product.warrantInDays + this.props.product.extendedWarrantInDays)
    let daysDiffDate = this.daysDiffDate

    if (daysDiffDate === 1) {
      return I18n.t('ultima_dia')
    } else if (daysDiffDate > -1) {
      return I18n.t('vencido_a') + ' ' + DateUtil.getMoment(endDate).fromNow() + '.'
    } else {
      return I18n.t('garantido_ate') + ' ' + DateUtil.getMoment(endDate).fromNow() + '.'
    }
  }

  render() {
    const { product, classes, onDonate, onRemove, style } = this.props
    const { anchorEl } = this.state

    let visibleTags = []
    let extraTags = 0
    visibleTags = product.productTags.slice(0, 3)
    extraTags = product.productTags.length - visibleTags.length

    return (
      <GridListTile cols={1} style={style}>
        <Paper className={classes.root}>
          <div className={classes.header}>
            <div className={classes.subheader}>
              <Typography>{product.name}</Typography>
              <div className={classes.tagsContainer}>
                {visibleTags.map(productTag => (
                  <Chip key={productTag.tag.id} classes={{ label: classes.chip, root: classes.chipRoot }} label={productTag.tag.name} />
                ))}
                {extraTags > 0 ? <Chip classes={{ label: classes.chip, root: cs(classes.chipRoot, classes.lastChip) }} label={'+' + extraTags} /> : null}
              </div>
            </div>
            <IconButton size="small" className={classes.buttonMenu} onClick={this.handleClick}>
              <MoreVertIcon />
            </IconButton>
          </div>
          <div className={classes.middle} onClick={this.handleEdit}>
            <div className={classes.warrantItem}>
              <Paper className={classes.circle1} elevation={0}>
                <Typography color="inherit" variant="h6">
                  {product.warrantInDays}
                </Typography>
              </Paper>
              <Typography variant="subtitle2">{I18n.t('geral')}</Typography>
            </div>
            <div className={classes.warrantItem}>
              <Paper className={classes.circle2} elevation={0}>
                <Typography color="inherit" variant="h6">
                  {product.extendedWarrantInDays}
                </Typography>
              </Paper>
              <Typography variant="subtitle2">{I18n.t('estendida')}</Typography>
            </div>
            <div className={classes.warrantItem}>
              <Paper className={classes.circle3} elevation={0}>
                <Typography color="inherit" variant="h6">
                  {product.warrantInDays + product.extendedWarrantInDays}
                </Typography>
              </Paper>
              <Typography variant="subtitle2">{I18n.t('total')}</Typography>
            </div>
            <Divider className={classes.vr} />
            <div className={classes.warrantItem}>
              <Paper className={classes.circle4} elevation={0}>
                {product.insuranceInDays ? (
                  <Typography color="inherit" variant="h6">
                    {product.insuranceInDays}
                  </Typography>
                ) : (
                  <Add color="inherit" />
                )}
              </Paper>
              <Typography variant="subtitle2">{I18n.t('seguro')}</Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.footer}>
            {product.wasDonated ? (
              <>
                <Favorite className={classes.donated} />
                <Typography variant="caption">{I18n.t('produto_doado')}</Typography>
              </>
            ) : (
              <>
                {this.hasWarrant() ? <Done className={classes.done} /> : <History className={classes.expirade} />}
                <Typography variant="caption">{this.labelDateText()}</Typography>
              </>
            )}
          </div>
        </Paper>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={this.handleClose}>
          <MenuItem onClick={this.handleEdit}>{I18n.t('edicao_produto')}</MenuItem>
          {!product.wasDonated && <MenuItem onClick={() => this.handleClose(() => onDonate(product.id))}>{I18n.t('doar_produto')}</MenuItem>}
          <MenuItem onClick={() => this.handleClose(() => onRemove(product.id))}>{I18n.t('excluir_produto')}</MenuItem>
        </Menu>
      </GridListTile>
    )
  }
}
