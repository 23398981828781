import React from 'react'
import BaseDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withMobileDialog } from '@material-ui/core'

@withMobileDialog()
class Dialog extends React.Component {
  render() {
    const { title, content, children, actions, className, contentClass, ...props } = this.props
    return (
      <BaseDialog {...props} className={className} maxWidth="lg">
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent className={contentClass}>{children || content}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </BaseDialog>
    )
  }
}

export default Dialog
