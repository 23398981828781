import React from 'react'
import { Paper, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import BusyButton from '../BusyButton'

const styles = theme => ({
  carinhaAbsoluteSoPraOcuparEspacoNaTela: {
    position: 'absolute',
    height: 70,
    pointerEvents: 'none',
    width: '100%'
  },
  footerBar: {
    width: '100%',
    height: 70,
    padding: '12px 8px',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    pointerEvents: 'visible',
    zIndex: 10
  },

  button: {
    padding: 0,
    flex: 1,
    margin: '0 4px',
    textTransform: 'none',

    maxWidth: 267,
    lineHeight: 1.1
  },

  noPrimaryButton: {
    padding: 0,
    flex: 2,
    margin: '0 6px',
    textTransform: 'none',

    maxWidth: 267
  },
  secondaryButton: {
    flex: 1,
    maxWidth: 280
  }
})

export default
@withStyles(styles)
class FooterBar extends React.Component {
  render() {
    const {
      classes,
      label,
      onClick,
      typeButton,
      secondaryButton,
      onSecondaryClick,
      secondaryLabel = 'Cancelar',
      busy,
      disabled,
      primaryButton,
      noPrimaryAction,
      tertiaryButton,
      onTertiaryButtonClick,
      tertiaryLabel
    } = this.props

    if (onClick || primaryButton || secondaryButton) {
      return (
        <div className={classes.carinhaAbsoluteSoPraOcuparEspacoNaTela}>
          <Paper className={classes.footerBar} elevation={0} square>
            {secondaryButton && (
              <Button
                color="secondary"
                fullWidth
                variant="outlined"
                className={`${classes.button} ${classes.secondaryButton}`}
                onClick={onSecondaryClick}
                // type={typeButton}
              >
                {secondaryLabel}
              </Button>
            )}
            {(primaryButton || onClick) && (
              <BusyButton
                busy={busy}
                color="secondary"
                fullWidth
                variant={noPrimaryAction ? 'outlined' : 'contained'}
                className={noPrimaryAction ? classes.noPrimaryButton : classes.button}
                onClick={onClick}
                type={typeButton}
                disabled={disabled}
              >
                {label}
              </BusyButton>
            )}
            {tertiaryButton && (
              <Button
                color="secondary"
                fullWidth
                variant="outlined"
                className={`${classes.button} ${classes.secondaryButton}`}
                onClick={onTertiaryButtonClick}
                // type={typeButton}
              >
                {tertiaryLabel}
              </Button>
            )}
          </Paper>
        </div>
      )
    } else return null
  }
}
