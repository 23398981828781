import React from 'react'
import {
  TextField,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core'
import Button from '../../atoms/Button'
// import { UserStore } from '@faicon/core'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
function Transition(props) {
  return <Slide direction="up" {...props} />
}

const style = {
  appbar: {
    position: 'relative',
    color: 'white'
  },

  textField: {
    margin: '15px 0'
  },

  button: {
    color: '#007AFF', // to-do: cor hardcoded
    border: '1px solid #007AFF', // to-do: cor hardcoded
    margin: 20
  }
}

class WorkingPlace extends React.Component {
  state = {
    open: false,
    rede: 'C&A',
    cnpj: '78.425.986/0036-15',
    razaoSocial: 'C&A'
  }

  open = () => {
    this.setState({ open: true })
  }

  handleCancel = () => {
    this.setState({ open: false })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { rede, cnpj, razaoSocial } = this.state
    return (
      <Dialog
        TransitionComponent={Transition}
        open={this.state.open}
        onClose={this.handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar style={style.appbar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCancel}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Local de Trabalho
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            name="rede"
            value={rede}
            autoFocus
            label="Rede"
            fullWidth
            onChange={this.handleChange}
            style={style.textField}
          />
          <TextField
            name="cnpj"
            value={cnpj}
            label="CNPJ"
            fullWidth
            onChange={this.handleChange}
            style={style.textField}
          />
          <TextField
            name="razao"
            value={razaoSocial}
            label="Razao Social"
            fullWidth
            onChange={this.handleChange}
            style={style.textField}
          />
        </DialogContent>
        <Button onClick={this.handleCancel} style={style.button}>
          Salvar Alteração
        </Button>
      </Dialog>
    )
  }
}

export default WorkingPlace
