import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Inbox from '@material-ui/icons/Inbox'

const style = theme => ({
  main: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column'
  },

  icon: {
    height: 'auto',
    width: 100,
    marginBottom: 8,
    color: theme.palette.grey[700]
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    padding: 26,
    justifyContent: 'center'
  }
})

export default
@withStyles(style)
class EmptyMessage extends React.Component {
  render() {
    const { classes, content = '', onClick, icon = true } = this.props

    return (
      <div className={classes.main} onClick={onClick}>
        {icon && <Inbox className={classes.icon} />}
        <div className={classes.message}>{content}</div>
      </div>
    )
  }
}
