import React from 'react'
import PropTypes from 'prop-types'
import keycode from 'keycode'
import Downshift from 'downshift'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  }
})

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }, labelFunction) {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {labelFunction(suggestion)}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}

class AutoCompleteServerSide extends React.Component {
  state = {
    inputValue: '',
    selectedItem: [],
    suggestions: []
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state
    if (selectedItem.length && !inputValue.length && keycode(event) === 'backspace') {
      this.setState(
        {
          selectedItem: selectedItem.slice(0, selectedItem.length - 1)
        },
        this.dispatchChange
      )
    }
  }

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value })
    this.props.store
      .where(this.props.where(event.target.value))
      .limit(10)
      .all()
      .then(suggestions => this.setState({ suggestions }))
  }

  handleChange = item => {
    let { selectedItem } = this.state

    if (selectedItem.filter(value => value.id === item.id).length === 0) {
      selectedItem = [...selectedItem, item]
    }

    this.setState(
      {
        inputValue: '',
        selectedItem
      },
      this.dispatchChange
    )
  }

  dispatchChange = () => {
    this.props.onChange(this.state.selectedItem)
  }

  handleDelete = item => () => {
    this.setState(state => {
      const selectedItem = [...state.selectedItem]
      selectedItem.splice(selectedItem.indexOf(item), 1)
      return { selectedItem }
    }, this.dispatchChange)
  }

  render() {
    const { classes, labelFunction, ...props } = this.props
    const { inputValue, selectedItem, suggestions } = this.state

    return (
      <Downshift id="downshift-multiple" inputValue={inputValue} onChange={this.handleChange} selectedItem={selectedItem} itemToString={labelFunction}>
        {({ getInputProps, getItemProps, isOpen, inputValue: inputValue2, selectedItem: selectedItem2, highlightedIndex }) => (
          <div className={classes.container}>
            {renderInput(
              Object.assign(
                {
                  classes,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true
                  },
                  InputProps: getInputProps({
                    startAdornment: selectedItem.map(item => (
                      <Chip key={item.id} tabIndex={-1} label={labelFunction(item)} className={classes.chip} onDelete={this.handleDelete(item)} />
                    )),
                    onChange: this.handleInputChange,
                    onKeyDown: this.handleKeyDown
                  })
                },
                props
              )
            )}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {suggestions.map((suggestion, index) =>
                  renderSuggestion(
                    {
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem2
                    },
                    labelFunction
                  )
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

export default withStyles(styles)(AutoCompleteServerSide)
