import React from 'react'
import Highcharts from 'highcharts'
import noData from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'

noData(Highcharts)

export default class SparkLine extends React.Component {
  getChartData = (props = this.props) => ({
    credits: {
      enabled: false
    },
    chart: {
      type: 'area',
      backgroundColor: null,
      borderWidth: 0,
      margin: [2, 0, 2, 0],
      width: 120,
      height: 20,
      style: {
        fontFamily: 'Roboto',
        overflow: 'visible'
      }
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: [0]
    },
    lang: {
      noData: '-'
    },
    loading: {
      style: {
        opacity: 0.8
      },
      labelStyle: {
        fontWeight: 400
      }
    },
    noData: {
      style: {
        fontWeight: 300,
        fontSize: 10
      }
    },
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      hideDelay: 0,
      shared: true,
      padding: 0,
      xDateFormat: '%B %Y',
      formatter: function() {
        return (
          props.categories[this.x] +
          ': <b>' +
          (props.percentage ? (this.y * 100).toFixed(0) + '%' : this.y) +
          '</b>'
        )
      }
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        },
        fillOpacity: 0.25
      }
    },
    series: this.props.series
  })

  render() {
    if (this.chart) {
      if (this.props.loading) {
        this.chart.chart.showLoading('Carregando...')
      } else {
        this.chart.chart.hideLoading()
      }
    }

    // console.log(this.props.showPeriodIndication)

    return (
      <HighchartsReact
        ref={me => (this.chart = me)}
        highcharts={Highcharts}
        options={this.getChartData()}
      />
    )
  }
}
