import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '100%'
  },

  wrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    height: 48,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },

  title: {
    fontSize: '1rem',
    color: 'black',
    fontWeight: '900'
  },
  rule: {
    fontSize: 13,
    color: '#666666'
  }
})

const Rules = ({ classes, rules }) => {
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <span className={classes.title}>Regulamento</span>
      </div>
      <div className={classes.rule}>
        <span dangerouslySetInnerHTML={{ __html: rules }} />
      </div>
    </div>
  )
}

export default withStyles(styles)(Rules)
