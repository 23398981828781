import _classCallCheck from "/Users/thiagolins/workspaces/react/novoto/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import moment from 'moment';

var DateUtil = function DateUtil() {
  var _this = this;

  _classCallCheck(this, DateUtil);

  this.addDays = function (date, days) {
    date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  this.now = function () {
    return moment().toDate();
  };

  this.nowDb = function () {
    return moment().format('YYYY-MM-DD HH:mm:ss');
  };

  this.expired = function (date) {
    var m = moment(date, 'YYYY-MM-DD h:mm:ss');
    var diff = moment().diff(m, 'days');
    console.log(diff);
    return diff > 7;
  };

  this.getMoment = function (date) {
    var m = moment(date, 'YYYY-MM-DD h:mm:ss');

    if (m.isValid()) {
      return m;
    } else {
      return null;
    }
  };

  this.getDate = function (date) {
    var m = _this.getMoment(date);

    if (m != null) {
      return m.toDate();
    } else {
      return null;
    }
  };

  this.addMonths = function (date, months) {
    var m = moment(date);
    m.month(m.month() + months);
    return m.toDate();
  };

  this.removeDays = function (date, days) {
    var m = moment(date);
    m.day(m.day() - days);
    return m.toDate();
  };

  this.getDifferenceInDays = function (date1, date2) {
    return Math.ceil((date1 - date2) / 86400000); // millisecondsPerDay = 1000 * 60 * 60 * 24
  };

  this.getDifferenceInDaysWithoutHours = function (date1, date2) {
    return _this.getDifferenceInDays(_this.removeHours(date1), _this.removeHours(date2));
  };

  this.upToNow = function (date) {
    return _this.findDuration(date, Date.now());
  };

  this.fromNow = function (date) {
    return _this.findDuration(Date.now(), date);
  };

  this.upToNowWithoutHours = function (date) {
    return _this.findDuration(date, _this.removeHours(Date.now()));
  };

  this.fromNowWithoutHours = function (date) {
    return _this.findDuration(_this.removeHours(Date.now()), date);
  };

  this.removeHours = function (date) {
    if (date == null) {
      return null;
    }

    date = new Date(date.valueOf());
    date.setHours(0, 0, 0, 0, 0);
    return date;
  };

  this.createTimeObject = function (label, value, index) {
    var weight = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
    return {
      label: label,
      value: value,
      index: index,
      weight: weight
    };
  };

  this.findDuration = function (earlierDate, laterDate) {
    var nTotalDiff = laterDate - earlierDate;
    var oDiff = {};
    oDiff.days = Math.floor(nTotalDiff / 1000 / 60 / 60 / 24);
    nTotalDiff -= oDiff.days * 1000 * 60 * 60 * 24;
    oDiff.hours = Math.floor(nTotalDiff / 1000 / 60 / 60);
    nTotalDiff -= oDiff.hours * 1000 * 60 * 60;
    oDiff.minutes = Math.floor(nTotalDiff / 1000 / 60);
    nTotalDiff -= oDiff.minutes * 1000 * 60;
    oDiff.seconds = Math.floor(nTotalDiff / 1000);
    oDiff.years = 0;
    oDiff.months = 0;

    if (oDiff.days > 364) {
      oDiff.years = Math.floor(oDiff.days / 365);
      oDiff.days = oDiff.days - oDiff.years * 365 - 1;
    }

    if (oDiff.days > 29) {
      oDiff.months = Math.floor(oDiff.days / 30);
      oDiff.days = oDiff.days - oDiff.months * 30 - 1;
    }

    var concatDiff = [];

    if (oDiff.years > 0) {
      if (oDiff.years === 1) {
        concatDiff.push(_this.createTimeObject('um ano', 1, 1));
      } else {
        concatDiff.push(_this.createTimeObject(oDiff.years + ' anos', oDiff.years, 1));
      }
    }

    if (oDiff.months > 0) {
      if (oDiff.months > 10) {
        if (concatDiff.length > 0) {
          return 'menos de ' + (concatDiff[0].value + 1) + ' anos';
        } else {
          return 'menos de um ano';
        }
      }

      if (oDiff.months === 1) {
        concatDiff.push(_this.createTimeObject('um mês', 1, 2));
      } else {
        concatDiff.push(_this.createTimeObject(oDiff.months + ' meses', oDiff.months, 2));
      }
    }

    if (oDiff.days > 0 && concatDiff.length < 2) {
      if (oDiff.days > 25) {
        if (concatDiff.length > 0) {
          return 'menos ' + (concatDiff[0].value + 1) + ' meses';
        } else {
          return 'menos de um mês';
        }
      }

      if (oDiff.days === 1) {
        concatDiff.push(_this.createTimeObject('um dia', 1, 3));
      } else {
        concatDiff.push(_this.createTimeObject(oDiff.days + ' dias', oDiff.days, 3, 5));
      }
    }

    if (oDiff.hours > 0 && concatDiff.length < 2) {
      if (oDiff.hours > 20) {
        if (concatDiff.length > 0) {
          return 'menos de ' + (concatDiff[0].value + 1) + ' dias';
        } else {
          return 'menos de um dia';
        }
      }

      if (oDiff.hours === 1) {
        concatDiff.push(_this.createTimeObject('uma hora', 1, 4));
      } else {
        concatDiff.push(_this.createTimeObject(oDiff.hours + ' horas', oDiff.hours, 4, 5));
      }
    }

    if (oDiff.minutes > 0 && concatDiff.length < 2) {
      if (oDiff.minutes > 50) {
        if (concatDiff.length > 0) {
          return 'menos de ' + (concatDiff[0].value + 1) + ' horas';
        } else {
          return 'menos de uma hora';
        }
      }

      if (oDiff.minutes === 1) {
        concatDiff.push(_this.createTimeObject('um minuto', 1, 5));
      } else {
        concatDiff.push(_this.createTimeObject(oDiff.minutes + ' minutos', oDiff.minutes, 5, 10));
      }
    }

    if (concatDiff.length === 0) {
      return 'alguns segundos';
    }

    if (concatDiff.length > 1) {
      if (concatDiff[0].index !== concatDiff[1].index - 1) {
        return 'mais de ' + concatDiff[0].label;
      } else {
        if (concatDiff[1].value < concatDiff[1].weight) {
          return 'mais de ' + concatDiff[0].label;
        } else {
          return concatDiff[0].label + ' e ' + concatDiff[1].label;
        }
      }
    } else {
      return concatDiff[0].label;
    }
  };
};

export default new DateUtil();