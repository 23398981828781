import React from 'react'
import { withStyles, IconButton } from '@material-ui/core'
import { ProductStore } from 'stores'
import { observer, inject, DataGridFetch, DataGridColumn, DateFormatter } from '@faicon/core'
import { Span } from 'components'

const styles = theme => ({
  root: { flex: 1 },
  iconButton: {
    padding: 8
  }
})

export default
@withStyles(styles)
@inject('router')
@observer
class Product extends React.Component {
  state = {}

  handleAdd = () => {
    this.props.router.pushSubpage('add')
  }

  handleClick = id => this.props.router.pushSubpage(id)

  render() {
    const { classes } = this.props

    return (
      <Span className={classes.root}>
        <DataGridFetch
          //  onItemClick={this.handleClick}
          store={ProductStore}
          searchableFields={['name', 'clientCpf', 'sellerCpf']}
          orderBy="id"
          order="asc"
        >
          <DataGridColumn title="Código" field="id" />
          <DataGridColumn title="Produto" field="name" />
          <DataGridColumn title="Id usuário" field="userId" />
          <DataGridColumn title="Email usuário" field="user.email" />
          {/* <DataGridColumn title="Id" field="productPicture" />
          <DataGridColumn title="Id" field="invoicePicture" /> */}
          <DataGridColumn title="Valor" field="price" />
          <DataGridColumn title="Data de compra" field="boughtAt" formatter={DateFormatter} />
          <DataGridColumn title="Garantia" field="warrantInDays" />
          <DataGridColumn title="Garantia estendida" field="extendedWarrantInDays" />
          <DataGridColumn title="Número seguro" field="insuranceNumber" />
          <DataGridColumn title="Valor seguro" field="insurancePrice" />
          <DataGridColumn title="Duração seguro" field="insuranceInDays" />
          <DataGridColumn title="Nome da loja" field="storeName" />
          <DataGridColumn title="Serial" field="serial" />
          <DataGridColumn title="Categoria" field="category" />
          <DataGridColumn title="Marca" field="brand" />
          <DataGridColumn title="Modelo" field="model" />
          {/* <DataGridColumn title="Id" field="latitude" />
          <DataGridColumn title="Id" field="longitude" /> */}
          <DataGridColumn title="Produto excluído" field="removedAt" formatter={DateFormatter} />
          {/* <DataGridColumn title="Id" field="productPath" />
          <DataGridColumn title="Id" field="invoicePath" /> */}
          <DataGridColumn title="Produto doado?" field="wasDonated" />
          {/* <DataGridColumn title="Id" field="productPictureSize" />
          <DataGridColumn title="Id" field="invoicePictureSize" /> */}
          <DataGridColumn title="Data cadastro" field="created" formatter={DateFormatter} />
          <DataGridColumn title="Obs" field="note" />
          <DataGridColumn
            width={100}
            render={(value, field, row) => (
              <IconButton className={classes.iconButton} onClick={() => this.handleClick} alt="Editar">
                <i className="material-icons">edit</i>
              </IconButton>
            )}
          />
        </DataGridFetch>
      </Span>
    )
  }
}
